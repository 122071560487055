import { useState, useEffect } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import { useNavigate, useParams } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { AuthModel } from 'app/modules/auth';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';
import { SelfService } from './model';
import TransactionAPPViewform from './app-view';

export const transactionModle = {
    transfer_company: 'az.trn.self-service',
};

const TransactionAppListView = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.self.service"

    const [selectedSelfService, setSelectedSelfService] = useState<SelfService>();
    const [currectEmployee, setCurrectEmployee] = useState<number>();
    const [selfServiceTypeDomain, setSelfServiceTypeDomain] = useState('');

    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-self-service-list-filter-hr-lite'
    const fields = "'id', 'name', 'employee_id', 'azadea_id', 'create_date', 'can_review', ('service_type_id', ('id', 'name')), 'employee_name', ('az_country_id',('name')), ('az_company_id',('name')), ('brand_id',('name')), ('job_id',('name')), 'x_state', 'state', 'x_state_view', 'restarted'"

    const [shopUser, setShopUser] = useState<boolean>(true);

    const updateListDataDomain = (currectEmployee_id, service_type, state) => {
        let filter = {}
        let conds: string[] = [];
        if (currectEmployee_id !== '' && currectEmployee_id && currectEmployee_id != 'undefined') {
            conds.push(`('employee_id','=', ${currectEmployee_id})`)
            if (handleChangeSelection) filter['employee_id'] = currectEmployee_id
        }
        if (service_type !== '' && service_type && service_type != 'undefined') {
            conds.push(`('service_type_id','=', ${service_type})`)
            if (handleChangeSelection) filter['service_type_id'] = service_type
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        return conds.join(',');
    }

    const [data, setData] = useState<SelfService[]>();
    const [pennding, setPending] = useState<boolean>(false);

    const viewResult = async (dataDomain) => {
        setPending(true);
        let remoteData = []
        try {
            const result = await axios.get(`${API_URL}/${MODEL_NAME}?filters=[${dataDomain}]&include_fields=[${fields}]&order=name DESC`); //&offset=${offset}&limit=${perPage}
            remoteData = result.data?.results ? result.data.results : []
            // setTotalRows(result?.data?.recordsTotal);
        } catch (error) {
            console.error(error);
        }
        // const data = await FixServerArrayData(remoteData)
        if (remoteData)
            setData(remoteData);
        setPending(false);
    }
    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            setCurrectEmployee(response.id)
            setSelfServiceTypeDomain(response.az_country_id ? `('country_id','=', ${response.az_country_id?.id})` : '');
            viewResult(updateListDataDomain(response.id, undefined, undefined))
        })
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
        if(id){
            let transaction:SelfService = {
                self_service_field: []
            }
            transaction['id'] = parseInt(id)
            setSelectedSelfService(transaction)
        }
    }, []);

    // #region status Dropdown
    const [az_state, setState] = useState('');

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: `('basic_status', '=', 'true')`,
        titleField: "name",
        allowEmpty: true,
        isSearchable: false,
        defaultValue: az_state,
        onChange: (value) => {
            setState(value)
        },
    });
    // #endregion status Dropdown

    // #region status Dropdown
    const [az_self_service_type, setSelfServiceType] = useState<any>('');

    const az_type_select = azkSelect({
        compID: "az_type_select",
        modelName: "az.self.service.type",
        valueField: "id",
        dataDomain: selfServiceTypeDomain,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        onChange: (value) => {
            if (value) setSelfServiceType(value)
            else setSelfServiceType('')
        },
        defaultValue: az_self_service_type
    });
    // #endregion Type Dropdown

    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(true);

    return (
        <>
            <Row className="m-0 p-0">
                <KTCard className='rounded-0 custom-input-container'>
                    <div className='card-body p-2'>
                        <div className='row m-0'>
                            <h6 className='py-2'>
                                Manage and submit your Self Service requests effortlessly
                            </h6>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='form-label'>Type</label>
                                {/* end::Label */}
                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_type_select}
                                </>)}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                                {/* begin::Label */}
                                <label className='form-label'>Status</label>
                                {/* end::Label */}
                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_state_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            <div className='fv-row col-lg-12 col-md-12 col-12'>
                                <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => viewResult(updateListDataDomain(currectEmployee, az_self_service_type, az_state))}>
                                    View Result
                                </button>
                            </div>

                        </div>
                    </div>
                </KTCard>
                <hr className="hr m-0" />
                {/* <KTCard className={`rounded-0 py-2 px-0 m-0 custom-KTCard-table ${mySelfServices? '':'custom-KTCard-table-with-check'}`} > */}
                <KTCard className={`rounded-0 pt-2 px-2 m-0 custom-KTCard-table }`} >
                    <div className='row m-0 d-flex justify-content-end p-0 m-0'>
                        <div className='d-flex justify-content-end p-0 m-0'>
                            <button type='button' className='btn btn-outline btn-color-dark btn-sm rounded-0' onClick={() => navigate(`/app/self-service/service/new`, { state: {}, replace: true })}>
                                New
                                <KTIcon iconName='plus' className='fs-2' />
                            </button>
                        </div>
                    </div>
                </KTCard>
            </Row>
            <Row className='m-0 px-3'>
                <h5 className="py-2 px-3 m-0 card-header custom-card-header">
                    My Requests
                </h5>
            </Row>
            <Row className='m-0 px-3 py-2' style={{ backgroundColor: '#D9D9D9', color: '#838383' }}>
                <Col lg="7" sm="6" className="service-header-label col-6">Details</Col>
                <Col lg="4" sm="4" className="service-header-label col-4">Status</Col>
                <Col lg="1" sm="2" className="service-header-label col-2"></Col>
            </Row>
            {data && data.length > 0 &&
                data.map(service => {
                    return (<>
                        <Row className='m-0 px-3 py-2'>
                            <Col lg="7" sm="6" className="col-6"><span className='service-form-label-value-bold '><b>{service.service_type_id.name}</b> </span><br />
                                <span className='service-form-label-value '>Date: {moment(service.create_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</span></Col>
                            <Col lg="4" sm="4" className="service-form-label-value col-4">{service.x_state_view}</Col>
                            <Col lg="1" sm="2" className='col-2'>
                                <button title='view-details' name='view-details' className='btn btn-outline btn-color-dark btn-sm border-0' onClick={() => setSelectedSelfService(service)}>
                                    <i className="bi bi-arrow-right fs-1 fw-bolder"></i>
                                </button>
                            </Col>
                        </Row>
                        <hr className="hr m-0" />
                    </>)
                })
            }
            <div>
                {selectedSelfService && <TransactionAPPViewform serviceId={selectedSelfService.id} closeModal={function (): {} {
                    setSelectedSelfService(undefined);
                    return false;
                }}></TransactionAPPViewform>}
            </div>
        </>)
}

export default TransactionAppListView;