import { GrouphModel } from "_azkatech/_models/_group"
import { Response } from "_metronic/helpers"
import { AZKHelper } from "app/modules/components/azkHelper"
import moment from "moment"

export type FinalSettlement = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean,
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  payroll_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  salary?: string
  target_salary?: string,
  as_effective_date?: string
  time_set?: string
  target_as_effective_date?: string
  can_edit?: boolean
  restarted?: boolean
  clearance_trn_id?: any
  salary_day?: number
  notice_period?: number
  leave_salary?: number
  indemnity?: number
  adjustment?: number
  other_deduction?: number
  other_addition?: number
  ticket_amount?: number
  ticket_amount_val?: number
  comment?: string,
  indemnity_zero?: boolean
  partial_payment?: boolean
  allowed_paramters?: string | undefined
  can_edit_params?: boolean | false
  nationality?: any,
  job_name?: any,
}

export const initialTransaction: FinalSettlement = {
  name: '',
  as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
  target_as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
  can_edit_params: true
}

export type FinalSettlementQueryResponse = Response<Array<FinalSettlement>>

export const initialTransferCompany: FinalSettlement = {
  can_edit_params: true

}
