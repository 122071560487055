import * as Yup from 'yup'
import { FinalSettlement, initialTransaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import moment from "moment";
import './style.scss'
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { GetClearanceForSettlementById } from "../clearance/_requests";
import { CreateFinalSettlement, GetFinalSettlementByClearanceId } from './_requests';
import { KTIcon } from '_metronic/helpers';

type Props = {
    transactionId: number,
    closeModal: () => {}
}
let reqired_fields = false
const editTransactionSchema = Yup.object().shape({
    nationality: Yup.string()
        .test('required', 'Nationality to is required', (val) => {
            if (reqired_fields && !val) {
                return false;
            }
            return true;
        }),
    job_name: Yup.string()
        .test('required', 'job_name to is required', (val) => {
            if (reqired_fields && !val) {
                return false;
            }
            return true;
        })
})

const FinalSettlementParametersModal: FC<Props> = ({ transactionId, closeModal }) => {
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<FinalSettlement>>(null);
    const [allowedParamters, setAllowedParamters] = useState<string>('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const pobulateFormData = async (data?: FinalSettlement) => {
        if (data) {
            setAllowedParamters(data?.allowed_paramters ? data?.allowed_paramters : '')
            let clerance_id = data.id
            if (data?.allowed_paramters?.includes('nationality')) {
                reqired_fields = true
            }
            if (data.clearance_trn_id) { clerance_id = data.clearance_trn_id.id }
            formRef.current?.setValues({
                id: undefined,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                payroll_id: data.payroll_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_set: data.time_set,
                salary: data.salary,
                target_salary: data.salary,
                azadea_employee_name: data.azadea_employee_name,
                clearance_trn_id: clerance_id,
                salary_day: data.salary_day,
                notice_period: data.notice_period,
                leave_salary: data.leave_salary,
                indemnity: data.indemnity,
                adjustment: data.adjustment,
                other_deduction: data.other_deduction,
                other_addition: data.other_addition,
                ticket_amount_val: data.ticket_amount_val,
                indemnity_zero: data.indemnity_zero,
                comment: data.comment,
                partial_payment: data.partial_payment,
                job_name: data.job_name,
                nationality: data.nationality,
                target_as_effective_date: moment(data?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
            })
        }
    }

    useEffect(() => {
        document.body.classList.add('page-loading')
        if (transactionId) {
            GetClearanceForSettlementById(transactionId).then((dataClearance: FinalSettlement) => {
                pobulateFormData(dataClearance)
                GetFinalSettlementByClearanceId(transactionId).then((data: FinalSettlement) => {
                    document.body.classList.remove('page-loading')
                    if (data) {
                        pobulateFormData(data)
                        if (data.can_edit_params) setCanEdit(data.can_edit_params)
                    } else {
                        setCanEdit(true)
                    }
                })
                /**
                 @todo: Create Function to check if current user has authirity to validatet theis transaction and replace the following block in all transactions
                **/
            })
        } else {
            document.body.classList.remove('page-loading')
        }
    }, [])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const onSubmitHandler = async (values: FinalSettlement, actions: FormikValues) => {
        document.body.classList.add('page-loading')
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            await CreateFinalSettlement(values).then(data => {
                document.body.classList.remove('page-loading')
                navigate(`/transactions/`, { state: {}, replace: true })
                Swal.fire({
                    position: "top-right",
                    title: 'Success!',
                    text: 'Your transaction had been saved!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            })
        } else {
        }
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>Final Settlement Parameters </h4>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y mx-5 mx-xl-7 my-5' >
                            <Row className="m-0 my-2">
                                <Col md='12' lg='12' xl='12'>
                                    <Formik
                                        innerRef={formRef}
                                        validationSchema={editTransactionSchema}
                                        onSubmit={onSubmitHandler}
                                        initialValues={initialTransaction}
                                        validateeOnChange={true}
                                        validateOnMount={true}
                                        initialErrors={''}>
                                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                                            // ,validateForm,isSubmitting, errors, touched, isValidating
                                            return (
                                                <><Form onSubmit={handleSubmit} noValidate>
                                                    <Row className="m-0">
                                                        <fieldset disabled={!canEdit}>
                                                            <Row className="m-0 my-2">
                                                                {allowedParamters && allowedParamters.includes('salary_day') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label >
                                                                                Salary Days
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('salary_day', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.salary_day}
                                                                                value={values.salary_day}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('notice_period') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label >
                                                                                Notice Period
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('notice_period', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.notice_period}
                                                                                value={values.notice_period}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('leave_salary') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Leave Salary
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('leave_salary', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.leave_salary}
                                                                                value={values.leave_salary}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('indemnity') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Indemnity
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('indemnity', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.indemnity}
                                                                                value={values.indemnity}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('adjustment') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Adjustment
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('adjustment', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.adjustment}
                                                                                value={values.adjustment}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('other_addition') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Other Additions
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                min='0'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('other_addition', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.other_addition}
                                                                                value={values.other_addition}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('other_deduction') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Other Deductions
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                max='0'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('other_deduction', parseFloat(val.target.value))
                                                                                }}
                                                                                defaultValue={values.other_deduction}
                                                                                value={values.other_deduction}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('ticket_amount') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Ticket Amount
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='number'
                                                                                disabled={values.indemnity_zero}
                                                                                value={values.ticket_amount_val}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('ticket_amount_val', parseFloat(val.target.value))
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('indemnity_zero') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Indemnity Zero
                                                                            </Form.Label>
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('salary_day', 0)
                                                                                    formRef.current?.setFieldValue('notice_period', 0)
                                                                                    formRef.current?.setFieldValue('leave_salary', 0)
                                                                                    formRef.current?.setFieldValue('indemnity', 0)
                                                                                    formRef.current?.setFieldValue('adjustment', 0)
                                                                                    formRef.current?.setFieldValue('other_addition', 0)
                                                                                    formRef.current?.setFieldValue('other_deduction', 0)
                                                                                    formRef.current?.setFieldValue('partial_payment', false)
                                                                                    formRef.current?.setFieldValue('indemnity_zero', val.target.checked)
                                                                                }}
                                                                                defaultChecked={values.indemnity_zero}
                                                                                className='p-1'
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('partial_payment') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Partial Payment
                                                                            </Form.Label>
                                                                            <Form.Check
                                                                                type="checkbox"
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('partial_payment', val.target.checked)
                                                                                }}
                                                                                defaultChecked={values.partial_payment}
                                                                                checked={values.partial_payment}
                                                                                className='p-1'
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}

                                                                {allowedParamters && allowedParamters.includes('nationality') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Nationality <span className='text-danger'>*</span>
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                required
                                                                                className='p-1'
                                                                                type='text'
                                                                                value={values.nationality}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('nationality', val.target.value)
                                                                                }}
                                                                            />
                                                                            <div className='text-danger'>
                                                                                <ErrorMessage name='nationality' />
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col></>)}

                                                                {allowedParamters && allowedParamters.includes('job_name') && (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                AR Job Name
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                type='text'
                                                                                value={values.job_name}
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('job_name', val.target.value)
                                                                                }}
                                                                            />
                                                                            <div className='text-danger'>
                                                                                <ErrorMessage name='job_name' />
                                                                            </div>
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                                {allowedParamters && allowedParamters.includes('comment') && (<>
                                                                    <Col lg="12" md="12" sm="12" xs="12">
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label>
                                                                                Comments
                                                                            </Form.Label>
                                                                            <Form.Control
                                                                                className='p-1'
                                                                                onChange={(val) => {
                                                                                    formRef.current?.setFieldValue('comment', val.target.value)
                                                                                }}
                                                                                defaultValue={values.comment}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col></>)}
                                                            </Row>
                                                        </fieldset>

                                                        {/* begin::Actions */}
                                                        <div className='py-5 d-flex justify-content-end'>
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                            >
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => closeModal()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        </div>
                                                        {/* end::Actions */}
                                                    </Row>
                                                </Form>
                                                </>
                                            )
                                        }}
                                    </Formik>
                                </Col>
                            </Row>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export default FinalSettlementParametersModal;

