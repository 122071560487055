import React, { useCallback, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import axios from 'axios';
import { API_URL } from './azkHelper';
import { FormikProps } from 'formik';
import { AsyncPaginate } from 'react-select-async-paginate';

type AzkSelectProps = {
  modelName: string;
  compID: string;
  valueField: string;
  titleField: string;
  dataDomain?: string | '';
  allowEmpty?: boolean | false;
  isSearchable?: boolean | true;
  isMultiple?: boolean | false;
  onChange?: Function | undefined;
  defaultValue?: any;
  fieldName?: string | '';
  disabled?: boolean;
  disableInitCall?: boolean;
  formRef?: FormikProps<any>;
  searchFields?: string[];
  disabledOptions?: any[];
  loadAll?: boolean;
};

type AzkOption = {
  value: any
  label: string
}

const AzkSelect: React.FC<AzkSelectProps> = ({ compID, modelName, valueField, titleField, dataDomain, allowEmpty, isSearchable, isMultiple, onChange, defaultValue, fieldName, disabled, disableInitCall, formRef, searchFields, disabledOptions, loadAll }) => {
  const [selectedOption, setSelectedOption] = useState<AzkOption>();
  const [fethcedIds, setFethcedIds] = useState<any[]>();
  const [needRefresh, setNeedRefresh] = useState<boolean>(true);

  useEffect(() => {
    setNeedRefresh(false)
    let defaultValueObj
    if (defaultValue && typeof defaultValue === 'string') {
      defaultValueObj = `'${defaultValue}'`
    } else if (defaultValue && typeof defaultValue === 'number') {
      defaultValueObj = defaultValue
    }
    if (defaultValueObj && selectedOption != defaultValueObj && (!fethcedIds || !fethcedIds.includes(defaultValueObj))) {
      axios.get(`${API_URL}/${modelName}?filters=[('${valueField}', '=', ${defaultValueObj})]&include_fields=['${valueField}', '${titleField}']&limit=1`).then(result => {
        if (result.data && result.data.results && result.data.count > 0) {
          setSelectedOption({ value: result.data.results[0][valueField], label: result.data.results[0][titleField] });
          setNeedRefresh(true)
        }
      });
    } else if (!defaultValueObj) {
      handleChange(null)
    } else {
      setNeedRefresh(true)
    }
  }, [modelName, valueField, dataDomain, titleField, disabled, defaultValue]);

  function handleChange(option: SingleValue<AzkOption>) {
    // Here, we invoke the callback with the new value
    if (option !== null && option !== undefined) {
      setSelectedOption({ value: option?.value, label: option.label })
    } else
      setSelectedOption(undefined)
    onChange && onChange(option !== null && option !== undefined ? option.value : null);
    if (formRef && fieldName && (option === null || option === undefined)) formRef.setFieldValue(fieldName, undefined)
    setNeedRefresh(true)
  }

  // #Todo handle isMulti
  async function loadOptions(search, options, { page }) {
    let searchDataDomain = ''
    if (search) {
      // search = encodeURIComponent(search)
      search = search.replace("'", "\\'").replace('"', '\\"').replace('\\', '\\\\').replace('\n', '\\n')
      search = encodeURIComponent(search)
      if (searchFields && searchFields !== undefined) {
        await searchFields.forEach(feild => {
          if (searchDataDomain !== '') {
            searchDataDomain = `'|',` + searchDataDomain + ','
          }
          searchDataDomain = searchDataDomain + `('${feild}','ilike', '${search}')`;
        })
        const domain = dataDomain && dataDomain !== '' && dataDomain !== undefined ? dataDomain + ',' + searchDataDomain : searchDataDomain
        return fetchOptions(options, { page }, domain)
      } else {
        searchDataDomain = `('${titleField}','ilike', '${search}')`;
        const domain = dataDomain && dataDomain !== '' && dataDomain !== undefined ? dataDomain + ',' + searchDataDomain : searchDataDomain
        return fetchOptions(options, { page }, domain)
      }
    } else {
      return fetchOptions(options, { page }, dataDomain !== undefined ? dataDomain : '')
    }
  }

  async function fetchOptions(filtered_option, { page }, searchDataDomain) {
    let result: any
    if (loadAll){
    result = await axios.get(`${API_URL}/${modelName}?filters=[${searchDataDomain}]&include_fields=['${valueField}', '${titleField}']&change_supervisor=True&limit=10&offset=${(page - 1) * 10}`);
    } else {
      result = await axios.get(`${API_URL}/${modelName}?filters=[${searchDataDomain}]&include_fields=['${valueField}', '${titleField}']&limit=10&offset=${(page - 1) * 10}`);
    }
    if (result.data && result.data.results && result.data.count > 0) {
      filtered_option = await result.data.results.map((item: any) => ({ value: item[valueField], label: item[titleField] }));
      setFethcedIds(result.data.results.map((item: any) => (item[valueField])))
      const hasMore = page * 10 < result.data.recordsFiltered
      // if (filtered_option && filtered_option.length === 1) setSelectedOption(filtered_option[0])
      return {
        options: filtered_option,
        hasMore: hasMore,
        additional: {
          page: page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  const extendedLoadOptions = useCallback(
    async (search, prevOptions, domain) => {
      const result = await loadOptions(search, prevOptions, domain);
      return result;
    },
    [modelName, valueField, dataDomain, titleField, disabled, defaultValue]
  );

  function checkIfOptionDisabled(option: AzkOption): boolean {
    if (disabledOptions && disabledOptions.includes(option.value)) {
      return true
    }
    return false
  }

  // if (disabled) {
  //   return (
  //     <AsyncPaginate
  //       key={selectedOption?.value}
  //       id={compID} name={fieldName}
  //       loadOptions={extendedLoadOptions}
  //       getOptionValue={(option: any) => option.value}
  //       getOptionLabel={(option: any) => option.label}
  //       onChange={(option) => {
  //         handleChange(option)
  //       }}
  //       additional={{
  //         page: 1,
  //       }}
  //       value={selectedOption}
  //       isDisabled={disabled}
  //       // isMulti={true}
  //       isClearable={true}
  //       loadOptionsOnMenuOpen={true}
  //       backspaceRemovesValue={true}
  //       cacheUniqs={cacheUniq}
  //       openMenuOnClick
  //       onMenuOpen={onMenuOpen}
  //     />
  //   );
  // } else {

  return (
    <>
      {needRefresh &&( <>
        {selectedOption &&(
        <div className="custom-tooltip col-12 p-0" data-tooltip={selectedOption?.label}>
        <AsyncPaginate
          key={selectedOption?.value}
          id={compID} name={fieldName}
          loadOptions={extendedLoadOptions}
          onBlur={() => {
            setNeedRefresh(false)
            setTimeout(() => {
              setNeedRefresh(true)
            }, 5)
          }}
          getOptionValue={(option: any) => option.value}
          getOptionLabel={(option: any) => option.label}
          onChange={(option) => {
            handleChange(option)
          }}
          additional={{
            page: 1,
          }}
          value={selectedOption}
          isDisabled={disabled}
          isOptionDisabled={(option) => checkIfOptionDisabled(option)}
          // isMulti={true}
          isClearable={allowEmpty}
          loadOptionsOnMenuOpen={true}
          backspaceRemovesValue={true}
          controlShouldRenderValue={true}
          openMenuOnClick
          debounceTimeout={1}
        />
        </div>
        )}
        {!selectedOption &&(
        <AsyncPaginate
          id={compID} name={fieldName}
          loadOptions={extendedLoadOptions}
          onBlur={() => {
            setNeedRefresh(false)
            setTimeout(() => {
              setNeedRefresh(true)
            }, 5)
          }}
          getOptionValue={(option: any) => option.value}
          getOptionLabel={(option: any) => option.label}
          onChange={(option) => {
            handleChange(option)
          }}
          additional={{
            page: 1,
          }}
          value={selectedOption}
          isDisabled={disabled}
          isOptionDisabled={(option) => checkIfOptionDisabled(option)}
          // isMulti={true}
          isClearable={allowEmpty}
          loadOptionsOnMenuOpen={true}
          backspaceRemovesValue={true}
          controlShouldRenderValue={true}
          openMenuOnClick
          debounceTimeout={1}
        />
        )}
        </>
        )}
    </>
  );
  // }
};

export default AzkSelect;