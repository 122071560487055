import * as Yup from 'yup'
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { KTIcon } from '_metronic/helpers';
import { TravelPerDiemLine } from './model';
import AzkSelect from '_azkatech/azkSelect';

type Props = {
    lineData?: TravelPerDiemLine,
    closeModal: () => {}
    confirmModal: (values) => {}
}
let required_fields = false
const editTransactionSchema = Yup.object().shape({
    flight_number: Yup.string()
    .test('flight-number-regex', 'Flight Number must match the pattern XX 1234', function (val) {
        if (required_fields && val) {
            const regex = /^[A-Z]{2} \d{1,4}$/;
            return regex.test(val);
        }
        return true;
    })
    .test('required', 'Flight Number to is required', (val) => {
        if (required_fields && !val) {
            return false;
        }
        return true;
    }),
    flight_date: Yup.string()
    .test('required', 'Flight Date to is required', (val) => {
        if (required_fields && !val) {
            return false;
        }
        return true;
    }),
    departure_city: Yup.string()
    .test('required', 'Departure City to is required', (val) => {
        if (required_fields && !val) {
            return false;
        }
        return true;
    }),
    arrival_city: Yup.string()
    .test('required', 'Arrival City to is required', (val) => {
        if (required_fields && !val) {
            return false;
        }
        return true;
    }),
    departure_time: Yup.string()
    .test('required', 'Departure time to is required', (val) => {
        if (required_fields && !val) {
            return false;
        }
        return true;
    }),
    arrival_time: Yup.string()
    .test('required', 'Arrival time to is required', (val) => {
        if (required_fields && !val) {
            return false;
        }
        return true;
    }),
})

// The trip Number: ME 304
// The departure date: 12AUG
// From: BEY
// To: CAI
// Departure Time: 0730
// Arrival Time: 0850

type TravelPerDiemLineCustom = {
    perdiem_x?: any,
    booked_travel?: any,
    flight_number?: any,
    flight_date?: any,
    departure_city?: any,
    arrival_city?: any,
    departure_time?: any,
    arrival_time?: any,
    comment?: any,
    is_euro_per_diem?: any,
}
const initialTransaction: TravelPerDiemLineCustom = {
    perdiem_x: 1,
    is_euro_per_diem: false,
}

const PerDiemModal: FC<Props> = ({ lineData, closeModal, confirmModal }) => {
    const Swal = require('sweetalert2')
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<TravelPerDiemLineCustom>>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);
    const pobulateFormData = async (data?: TravelPerDiemLineCustom) => {
        if (data) {
            formRef.current?.setValues({
                perdiem_x: parseFloat(data?.perdiem_x) || 1,
                booked_travel: data?.booked_travel,
                flight_number: data?.flight_number,
                flight_date: data?.flight_date,
                departure_city: data?.departure_city,
                arrival_city: data?.arrival_city,
                departure_time: data?.departure_time,
                arrival_time: data?.arrival_time,
                comment: data?.comment,
                is_euro_per_diem: data?.is_euro_per_diem || false,
            })
        }
    }

    useEffect(() => {
        // document.body.classList.add('page-loading')
        if (lineData) {
            pobulateFormData(lineData)
            setEdit(true)
            required_fields = true
        } else {
            document.body.classList.remove('page-loading')
            setEdit(false)
            required_fields = false
        }
    }, [])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const onSubmitHandler = async (values: TravelPerDiemLineCustom, actions: FormikValues) => {
        // document.body.classList.add('page-loading')
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            confirmModal(values)
        }
    }

    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-650px'>
                    {/* begin::Modal content */}
                    <div className='modal-content'>

                        <div className='modal-header py-2'>
                            {/* begin::Modal title */}
                            <h4 className='m-0'>New Per diem</h4>
                            {/* end::Modal title */}

                            {/* begin::Close */}
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => closeModal()}
                                style={{ cursor: 'pointer' }}
                            >
                                <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
                            </div>
                            {/* end::Close */}
                        </div>
                        {/* begin::Modal body */}
                        <div className='modal-body scroll-y p-0' >
                            <Row className="m-0 my-2">
                                <Col md='12' lg='12' xl='12'>
                                    <Formik
                                        innerRef={formRef}
                                        validationSchema={editTransactionSchema}
                                        onSubmit={onSubmitHandler}
                                        initialValues={initialTransaction}
                                        validateeOnChange={true}
                                        validateOnMount={true}
                                        initialErrors={''}>
                                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                                            // ,validateForm,isSubmitting, errors, touched, isValidating
                                            return (
                                                <><Form onSubmit={handleSubmit} noValidate>
                                                    <Row className="m-0">
                                                        <fieldset>
                                                            <Row className="m-0 my-2">
                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                    {!edit &&
                                                                        <Form.Group className='mb-2'>
                                                                            <Form.Label >
                                                                                Booked
                                                                            </Form.Label>
                                                                            <Row className="m-0">
                                                                                <textarea
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.booked_travel}
                                                                                    value={values.booked_travel}
                                                                                />
                                                                            </Row>
                                                                        </Form.Group>
                                                                    }
                                                                    {edit &&
                                                                        <Row className="m-0">

                                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                                <Form.Label >
                                                                                    Flight Number
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', values.booked_travel.replace(values.flight_number, val.target.value))
                                                                                        formRef.current?.setFieldValue('flight_number', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.flight_number}
                                                                                    value={values.flight_number}
                                                                                />
                                                                                <div className='text-danger'>
                                                                                    <ErrorMessage name='flight_number' />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                                <Form.Label >
                                                                                    Departure City
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', values.booked_travel.replace(values.departure_city, val.target.value))
                                                                                        formRef.current?.setFieldValue('departure_city', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.departure_city}
                                                                                    value={values.departure_city}
                                                                                />
                                                                                <div className='text-danger'>
                                                                                    <ErrorMessage name='departure_city' />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                                <Form.Label >
                                                                                    Arrival City
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', values.booked_travel.replace(values.arrival_city, val.target.value))
                                                                                        formRef.current?.setFieldValue('arrival_city', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.arrival_city}
                                                                                    value={values.arrival_city}
                                                                                />
                                                                                <div className='text-danger'>
                                                                                    <ErrorMessage name='arrival_city' />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                                <Form.Label >
                                                                                    Flight Date
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', values.booked_travel.replace(values.flight_date, val.target.value))
                                                                                        formRef.current?.setFieldValue('flight_date', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.flight_date}
                                                                                    value={values.flight_date}
                                                                                />
                                                                                <div className='text-danger'>
                                                                                    <ErrorMessage name='flight_date' />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                                <Form.Label >
                                                                                    Departure Time
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', values.booked_travel.replace(values.departure_time, val.target.value))
                                                                                        formRef.current?.setFieldValue('departure_time', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.departure_time}
                                                                                    value={values.departure_time}
                                                                                />
                                                                                <div className='text-danger'>
                                                                                    <ErrorMessage name='departure_time' />
                                                                                </div>
                                                                            </Col>
                                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                                <Form.Label >
                                                                                    Arrival Time
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    className='p-1'
                                                                                    onChange={(val) => {
                                                                                        formRef.current?.setFieldValue('booked_travel', values.booked_travel.replace(values.arrival_time, val.target.value))
                                                                                        formRef.current?.setFieldValue('arrival_time', val.target.value)
                                                                                    }}
                                                                                    defaultValue={values.arrival_time}
                                                                                    value={values.arrival_time}
                                                                                />
                                                                                <div className='text-danger'>
                                                                                    <ErrorMessage name='arrival_time' />
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    }
                                                                </Col>
                                                                <Col lg="12" md="12" sm="12" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label >
                                                                            Per Diem
                                                                        </Form.Label>
                                                                        <Row className="m-0">
                                                                            <Form.Check
                                                                                className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                label={"Zero"}
                                                                                value={0}
                                                                                defaultChecked={0 === values?.perdiem_x}
                                                                                name="perdiem_x"
                                                                                onClick={(value) => {
                                                                                    formRef.current?.setFieldValue('perdiem_x', 0)
                                                                                }}
                                                                                type="radio"
                                                                            />
                                                                            <Form.Check
                                                                                className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                label={"X 1/2"}
                                                                                value={0.5}
                                                                                defaultChecked={0.5 === values?.perdiem_x}
                                                                                name="perdiem_x"
                                                                                onClick={(value) => {
                                                                                    formRef.current?.setFieldValue('perdiem_x', 0.5)
                                                                                }}
                                                                                type="radio"
                                                                            />
                                                                            <Form.Check
                                                                                className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                label={"X 1"}
                                                                                value={1}
                                                                                defaultChecked={1 === values?.perdiem_x}
                                                                                name="perdiem_x"
                                                                                onClick={(value) => {
                                                                                    formRef.current?.setFieldValue('perdiem_x', 1)
                                                                                }}
                                                                                type="radio"
                                                                            />
                                                                            <Form.Check
                                                                                className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                label={"X 1.5"}
                                                                                value={1.5}
                                                                                defaultChecked={1.5 === values?.perdiem_x}
                                                                                name="perdiem_x"
                                                                                onClick={(value) => {
                                                                                    formRef.current?.setFieldValue('perdiem_x', 1.5)
                                                                                }}
                                                                                type="radio"
                                                                            />
                                                                            <Form.Check
                                                                                className='col-lg-2 col-md-2 col-sm-3 col-4'
                                                                                label={"X 2"}
                                                                                value={2}
                                                                                defaultChecked={2 === values?.perdiem_x}
                                                                                name="perdiem_x"
                                                                                onClick={(value) => {
                                                                                    formRef.current?.setFieldValue('perdiem_x', 2)
                                                                                }}
                                                                                type="radio"
                                                                            />
                                                                        </Row>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg="4" md="4" sm="4" xs="4">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label >
                                                                            Comment
                                                                        </Form.Label>
                                                                        <AzkSelect
                                                                            compID="az.travel.request.setting.comment"
                                                                            modelName="az.travel.request.setting.comment"
                                                                            valueField="id"
                                                                            titleField="comment"
                                                                            dataDomain={``}
                                                                            allowEmpty={true}
                                                                            fieldName='id'
                                                                            onChange={(value) => { setFieldValue('comment', parseInt(value)) }}
                                                                            defaultValue={values.comment}
                                                                            searchFields={['comment']} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg="8" md="8" sm="8" xs="8" className=' align-self-end'>
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label >

                                                                        </Form.Label>
                                                                        <Row className="m-0">
                                                                            <Form.Check
                                                                                className='col-lg-4 col-md-4 col-sm-6 col-6'
                                                                                label={"Not Europ"}
                                                                                checked={true !== values?.is_euro_per_diem}
                                                                                name="is_euro_per_diem"
                                                                                onChange={(value) => {
                                                                                    if (value.target.checked) formRef.current?.setFieldValue('is_euro_per_diem', false)
                                                                                }}
                                                                                type="radio"
                                                                            // defaultValue={values?.is_euro_per_diem}
                                                                            />
                                                                            <Form.Check
                                                                                className='col-lg-4 col-md-4 col-sm-6 col-6'
                                                                                label={"Europ"}
                                                                                checked={true === values?.is_euro_per_diem}
                                                                                name="is_euro_per_diem"
                                                                                onChange={(value) => {
                                                                                    if (value.target.checked) formRef.current?.setFieldValue('is_euro_per_diem', true)
                                                                                }}
                                                                                type="radio"
                                                                            // defaultValue={values?.is_euro_per_diem}
                                                                            />
                                                                        </Row>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </fieldset>

                                                        {/* begin::Actions */}
                                                        <div className='py-5 d-flex justify-content-end'>
                                                            <button
                                                                title="Save"
                                                                type='submit'
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                            >
                                                                <i className="fas fa-check fs-2"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => closeModal()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        </div>
                                                        {/* end::Actions */}
                                                    </Row>
                                                </Form>
                                                </>
                                            )
                                        }}
                                    </Formik>
                                </Col>
                            </Row>
                        </div>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}

export default PerDiemModal;

