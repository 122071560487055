import axios, { ResponseType } from "axios";
import { Leave } from "./model";
import { FC, useEffect, useState } from "react";
import { GetLeaveById, MODEL_NAME } from "./_requests";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Form, Row, Col } from "react-bootstrap";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { useParams } from "react-router-dom";

type Props = {
    leaveId: any,
    closeModal: () => {}
}

const TransactionViewform: FC<Props> = ({ leaveId, closeModal }) => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const [leave, setLeave] = useState<Leave>();

    useEffect(() => {
        document.body.classList.add('page-loading')
        if (leaveId) {
            document.body.classList.add('page-loading')
            GetLeaveById(parseInt(leaveId)).then(((data: Leave) => {
                setLeave(data)
                document.body.classList.remove('page-loading')
            }))
        } else {
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        closeModal();
    }

    const rejectSelectedLeave = () => {
        if (leaveId) {
            document.body.classList.add('page-loading')
            axios.put(`${API_URL}/${MODEL_NAME}/${leaveId}/reject_tier`, {}).then(data => {
                Swal.fire({
                    position: "center",
                    // title: 'Success!',
                    text: 'Leave had been rejected!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
                document.body.classList.remove('page-loading')
                closeModal();
            }, err => {
                document.body.classList.remove('page-loading')
            })
        }
    }

    const approveSelectedLeave = () => {
        if (leaveId) {
            document.body.classList.add('page-loading')
            axios.put(`${API_URL}/${MODEL_NAME}/${leaveId}/validate_tier`, {}).then(data => {
                Swal.fire({
                    position: "center",
                    // title: 'Success!',
                    text: 'Leave had been approved!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
                document.body.classList.remove('page-loading');
                closeModal();
            }, err => {
                document.body.classList.remove('page-loading')
            })
        }
    }

    const downloadFilePDF = (file_id, responseType: ResponseType = 'blob') => {
        if (file_id) {
            axios
                .get(`${API_URL}/attachments/370`, {
                    responseType,
                })
                .then((response) => {
                    let file_name = response.headers['content-disposition']
                    file_name = file_name.replace('attachment; filename=', '')
                    file_name = file_name.replaceAll('\"', '')
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', file_name)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.error('Download error:', error)
                })
        }
    }

    // Convert a float to milliseconds
    function convertToMs(timefloat) {
        // Get the minutes portion
        let remainder = timefloat % 1;
        if (Number.isNaN(remainder)){
            return moment(timefloat, "HH:mm");
        }
        // Convert into milliseconds
        let minutes = remainder * 100 * 60 * 1000;

        // Get the number of hours and convert to milliseconds
        let hours = (timefloat - remainder) * 60 * 60 * 1000;

        return minutes + hours;
        
    }

    return (
        <>
            {leave &&
                <><div
                    className='modal fade show d-block'
                    id='kt_modal_add_user'
                    role='dialog'
                    tabIndex={-1}
                    aria-modal='true'
                    style={{ zIndex: 99999 }}
                >
                    {/* begin::Modal dialog */}
                    <div className='modal-dialog modal-dialog-centered px-3'>
                        {/* begin::Modal content */}
                        <div className='modal-content'>

                            <div className='modal-header py-3 px-2'>
                                {/* begin::Modal title */}
                                <h4 className='m-0'>Leave Details</h4>
                                {/* end::Modal title */}

                                {/* begin::Close */}
                                {/* <div
                                    className='btn btn-icon btn-sm btn-active-icon-primary'
                                    data-kt-users-modal-action='close'
                                    onClick={() => closeModal()}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
                                </div> */}
                                {/* end::Close */}
                            </div>
                            {/* <div className='text-center py-3'>
                            <h4 className='m-0'>Leave Details</h4>
                        </div> */}
                            {/* begin::Modal body */}
                            <div className='' >
                                <Row className="m-0 py-3">
                                    <Col lg="4" md="4" sm="6" xs="12">
                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                            <Form.Label className="payslip-form-label">
                                                Id/Name
                                            </Form.Label>
                                            <Form.Label className="payslip-form-label-value">
                                                {leave?.azadea_id} / {leave?.employee_id.name}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {/* <Col lg="4" md="4" sm="6" xs="12">
                                    <Form.Group className='mb-2 d-flex justify-content-between'>
                                        <Form.Label className="payslip-form-label">
                                            Employee
                                        </Form.Label>
                                        <Form.Label className="payslip-form-label-value">
                                            {leave?.employee_id.name}
                                        </Form.Label>
                                    </Form.Group>
                                </Col> */}
                                    <Col lg="4" md="4" sm="6" xs="12">
                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                            <Form.Label className="payslip-form-label">
                                                Leave Type
                                            </Form.Label>
                                            <Form.Label className="payslip-form-label-value">
                                                {leave?.leave_type_id?.name}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4" md="4" sm="6" xs="12">
                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                            <Form.Label className="payslip-form-label">
                                                Date From
                                            </Form.Label>
                                            <Form.Label className="payslip-form-label-value">
                                                {moment(leave?.date_from).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4" md="4" sm="6" xs="12">
                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                            <Form.Label className="payslip-form-label">
                                                Date To
                                            </Form.Label>
                                            <Form.Label className="payslip-form-label-value">
                                                {moment(leave?.date_to).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>

                                    {AZKHelper.MATERNITY_LEAVE_CODE === leave?.leave_type_id?.lookup_code && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Expected delivery date
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {moment(leave?.expected_delivery_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>}
                                    {AZKHelper.PATERNITY_LEAVE_CODE === leave?.leave_type_id?.lookup_code && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Childbirth date
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {moment(leave?.expected_delivery_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>}
                                    {AZKHelper.BUSINESS_TRAVEL_LEAVE_CODE === leave?.leave_type_id?.lookup_code && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Country Visited
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {leave?.country_visited_id?.name}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Reason for travel
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {leave?.reason_for_travel_id?.name}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>}
                                    {AZKHelper.BEREAVEMENT_LEAVE_CODE === leave?.leave_type_id?.lookup_code && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Relation Ship
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {leave?.relationship_type_id?.name}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Family Member
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {leave?.family_member_id?.name}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>}
                                    {AZKHelper.HALF_DAY === leave?.leave_type_id?.lookup_code && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Leave Time
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {moment(convertToMs(leave?.time_to)).format(AZKHelper.FRONTEND_TIME_FORMAT)}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>
                                    }
                                    {leave?.leave_type_id?.is_hourly && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Time From
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {moment(convertToMs(leave?.time_from)).format(AZKHelper.FRONTEND_TIME_FORMAT)}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Time To
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    {moment(convertToMs(leave?.time_to)).format(AZKHelper.FRONTEND_TIME_FORMAT)}
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>
                                    }
                                    {leave?.document && <>
                                        <Col lg="4" md="4" sm="6" xs="12">
                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                <Form.Label className="payslip-form-label">
                                                    Report
                                                </Form.Label>
                                                <Form.Label className="payslip-form-label-value">
                                                    <button
                                                        title="Download PDF"
                                                        type='reset'
                                                        onClick={() => downloadFilePDF(leave.document.id)}
                                                        className='btn btn-dark rounded-0 btn-sm col-12'
                                                        data-kt-users-modal-action='Download PDF'
                                                    >
                                                        <span className='indicator-label'>Download PDF</span>
                                                    </button>
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                    </>
                                    }

                                </Row>
                            </div>
                            {/* end::Modal body */}
                            <div className='my-2 row m-0'>
                                <div className={`${leave?.can_review ? 'col-12 d-flex justify-content-between py-3':'col-12 d-flex justify-content-end py-3'}`}>
                                    {leave?.can_review && <>
                                        <button id='approve-button' type='button' className='btn btn-dark rounded-0 btn-sm col-3'
                                            onClick={() => approveSelectedLeave()}>
                                            <span className='indicator-label'>Approve</span>
                                        </button>
                                        <button id='reject-button' type='button' className='btn btn-outline btn-color-dark rounded-0 btn-sm col-3'
                                            onClick={() => rejectSelectedLeave()}>
                                            <span className='indicator-label'>Reject</span>
                                        </button></>
                                    }
                                    <button id='discard-button' type='button' className='btn btn-dark rounded-0 btn-sm col-3'
                                        onClick={() => closeModal()}>
                                        <span className='indicator-label'>Discard</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* end::Modal content */}
                    </div>
                    {/* end::Modal dialog */}
                </div>
                    {/* begin::Modal Backdrop */}
                    <div className='modal-backdrop fade show'></div>
                    {/* end::Modal Backdrop */}
                </>
            }
        </>
    )
}

export default TransactionViewform;