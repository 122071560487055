import React, { useState, useEffect, ReactHTML } from 'react';
import axios, { ResponseType } from 'axios';
import azkSelect from "_azkatech/azkSelect"
import azkDataTable from "_azkatech/azkDataTable"
import { useNavigate, useParams } from 'react-router';
import { KTCard, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { Col, Form, Row } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { AuthModel } from 'app/modules/auth';
import Swal from 'sweetalert2';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';
import AzkSelect from '_azkatech/azkSelect';
import TransactionViewform from './view-form';
import { initialLeave, Leave } from './model';

export const transactionModle = {
    transfer_company: 'az.trn.leave',
};

const TransactionAppListView = () => {
    const { id } = useParams();
    const [listDataDomain, setListDataDomain] = useState("");
    const [employeeId, setEmployeeId] = useState<number>()
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.leave"

    const [selectedLeave, setSelectedLeave] = useState<Leave>();
    const [isManger, setIsManger] = useState<boolean>(false);
    const [currectEmployee, setCurrectEmployee] = useState<number>();
    const [deleted, setDeleted] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any[]>([]);
    const [selectedDataIds, setSelectedDataIds] = useState<any[]>([]);
    const [userId, setUserId] = useState<number>();

    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const [pageLoaded, setPageLoaded] = useState<boolean>(false);
    const [forceRefresh, setForceRefresh] = useState<boolean>(false);
    const [myLeaves, setMyLeaves] = useState<boolean>(true);

    // #region Transfer List as DataTable
    const TRN_list_LOCAL_STORAGE_FILTER_KEY = 'azk-trn-leave-list-filter-hr-lite'
    const fields = "'id', 'name', 'employee_id', 'azadea_id', 'create_date', 'can_review', ('leave_type_id', ('id', 'name','is_hourly', 'lookup_code')), 'date_from', 'date_to', 'employee_name', ('az_country_id',('name')), ('az_company_id',('name')), ('brand_id',('name')), ('job_id',('name')), 'x_state', 'state', 'x_state_view', 'restarted'"

    const [shopUser, setShopUser] = useState<boolean>(true);

    const updateListDataDomain = (currectEmployee_id, employee_id, leave_type, state, myRequest, dateFrom, dateTo, deleted, isManger, shopUSer) => {
        let filter = {}
        let conds: string[] = [];
        if (leave_type !== '' && leave_type && leave_type != 'undefined') {
            conds.push(`('leave_type_id','=', ${leave_type})`)
            if (handleChangeSelection) filter['az_leave_type'] = leave_type
        }
        if (state !== '' && state && state != 'undefined') {
            conds.push(`('x_state','ilike', '${state}')`)
            if (handleChangeSelection) filter['x_state'] = state
        }
        if (employee_id !== '' && employee_id && employee_id != 'undefined') {
            conds.push(`('employee_id','=', ${employee_id})`)
            if (handleChangeSelection) filter['employee_id'] = employee_id
        }
        if (dateFrom && moment(dateFrom).isValid()) {
            conds.push(`('date_from','>=', '${moment(dateFrom).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
            if (handleChangeSelection) filter['date_from'] = dateFrom
        }
        if (dateTo && moment(dateTo).isValid()) {
            conds.push(`('date_to','<=', '${moment(dateTo).utc(true)?.format(AZKHelper.BACKEND_DATE_FORMAT)}')`)
            if (handleChangeSelection) filter['date_to'] = dateTo
        }
        if (!isManger || myRequest) {
            conds.push(`('employee_id','=', ${currectEmployee_id})`)
        } else {
            if (isManger && shopUSer) {
                conds.push(`'|', ('employee_id.parent_id','=', ${currectEmployee_id}), ('employee_id.super_parent_id','=', ${currectEmployee_id})`)
            }
            if (isManger && !shopUSer) {
                conds.push(`('employee_id.parent_id','=', ${currectEmployee_id})`)
            }
        }
        const lsValue = JSON.stringify(filter)
        if (handleChangeSelection) localStorage.setItem(TRN_list_LOCAL_STORAGE_FILTER_KEY, lsValue)
        setPageLoaded(true)
        return conds.join(',');
    }
    const columns_my_leaves = [
        {
            name: <> <span className='w-100 fw-bold  text-center'>Type</span></>,
            align: 'center',
            // width: '25%',
            selector: row => row.leave_type_id ? row.leave_type_id.name : undefined,
            sortable: true,
            minWidth: '85px',
            // maxWidth: '85px',
            style: {
                'word-break': 'auto-phrase'
            },
            tech_name: 'leave_type_id',
            cell: (d) => [(<>
                <Row className='table-cell m-0' onClick={() => { setSelectedLeave(d) }}>
                    {d.leave_type_id.name}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 fw-bold  text-center'>From / To</span></>,
            align: 'center',
            // width: '25%',
            selector: row => row.date ? row.date_from : undefined,
            sortable: true,
            minWidth: '85px',
            // maxWidth: '85px',
            tech_name: 'date_from',
            cell: (d) => [(<>
                <Row className='table-cell m-0' onClick={() => { setSelectedLeave(d) }}>
                    {moment(d.date_from).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                    <br />
                    {moment(d.date_to).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 fw-bold  text-center'>Status</span> </>,
            selector: row => row.x_state ? row.x_state : undefined,
            sortable: true,
            align: 'center',
            minWidth: '75px',
            // maxWidth: '75px',
            // width: '20%',
            tech_name: 'x_state',
            style: {
                'text-wrap': 'nowrap'
            },
            class: 'p-0 m-0',
            cell: (d) => [
                <Row className='table-cell m-0' onClick={() => { setSelectedLeave(d) }}>
                    {d.x_state_view}
                </Row>
            ]
        },
    ];
    const columns = [
        {
            name: <> <span className='w-100 fw-bold  text-center'>Type</span></>,
            align: 'center',
            // width: '25%',
            selector: row => row.leave_type_id ? row.leave_type_id.name : undefined,
            sortable: true,
            minWidth: '85px',
            // maxWidth: '85px',
            style: {
                'word-break': 'auto-phrase'
            },
            tech_name: 'leave_type_id',
            cell: (d) => [(<>
                <Row className='table-cell m-0' onClick={() => { setSelectedLeave(d) }}>
                    {d.leave_type_id.name}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 fw-bold  text-center'>Employee</span></>,
            // width: '25%',
            selector: row => row.employee_id.name ? row.employee_id.name : undefined,
            sortable: false,
            align: 'center',
            minWidth: '85px',
            // maxWidth: '85px',
            tech_name: 'employee_id.name',
            style: {
                // 'text-wrap': 'nowrap'
            },
            cell: (d) => [(<>
                <Row className='m-0' onClick={() => { setSelectedLeave(d) }}>
                    <Row className='table-cell m-0'>
                        {d.employee_id.name}
                    </Row>
                </Row>
            </>)
            ]
        },
        {
            name: <> <span className='w-100 fw-bold  text-center'>From / To</span></>,
            align: 'center',
            // width: '25%',
            selector: row => row.date ? row.date_from : undefined,
            sortable: true,
            minWidth: '85px',
            // maxWidth: '85px',
            tech_name: 'date_from',
            cell: (d) => [(<>
                <Row className='table-cell m-0' onClick={() => { setSelectedLeave(d) }}>
                    {moment(d.date_from).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                    <br />
                    {moment(d.date_to).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </Row>
            </>)]
        },
        {
            name: <> <span className='w-100 fw-bold  text-center'>Status</span> </>,
            selector: row => row.x_state ? row.x_state : undefined,
            sortable: true,
            align: 'center',
            minWidth: '70px',
            // maxWidth: '75px',
            // width: '20%',
            tech_name: 'x_state',
            style: {
                'text-wrap': 'nowrap'
            },
            class: 'p-0 m-0',
            cell: (d) => [
                <Row className='table-cell m-0' onClick={() => { setSelectedLeave(d) }}>
                    {d.x_state_view}
                </Row>
            ]
        },
    ];

    const onRowClickedFunction = async (row) => {
        setSelectedLeave(row)
    }
    const getDataToExport = async (data: any[]) => {
        if (data) {
            setSelectedData(data)
            let excelDataListIds: any[] = []
            if (data) {
                data.forEach(rec => {
                    excelDataListIds.push(rec['id'])
                    setSelectedDataIds(excelDataListIds)
                })
            }
        }
    }

    const table = azkDataTable({
        modelName: MODEL_NAME,
        fields: fields,
        dataDomain: listDataDomain,
        columns: myLeaves ? columns_my_leaves : columns,
        selectableRows: false,
        dataOrder: 'create_date desc, name desc',
        exportFunction: getDataToExport,
        pageReady: pageLoaded,
        diableSelectableRows: true,
        onRowClickedFunction: onRowClickedFunction,
        forceRefresh: forceRefresh,
    })

    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        const from_date = moment().startOf('month').format(AZKHelper.BACKEND_DATE_FORMAT)
        const to_date = moment().startOf('month').add(1, 'month').format(AZKHelper.BACKEND_DATE_FORMAT)
        setDateFrom(from_date)
        setDateTo(to_date)
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        setUserId(_auth?.uid)
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            if (response && response.is_manager_fr && response.is_manager_fr !== null) {
                setIsManger(true)
                setCurrectEmployee(response.id)
            } else {
                setIsManger(false)
                setCurrectEmployee(response.id)
            }
            const andCondition = encodeURIComponent('&')
            setLeaveTypeDomain(response.az_country_id ? `'${andCondition}', ('country_id','=', ${response.az_country_id?.id}), '|', ('payroll_id', '=', ${response.payroll_id?.id}), ('payroll_id', '=', False)` : '');
            if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
            // if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
            const filterVals: string | null = localStorage.getItem(TRN_list_LOCAL_STORAGE_FILTER_KEY)
            if (filterVals) {
                const jsonObj = JSON.parse(filterVals)
                // setShowFilters(jsonObj['showFilters'])
                if (jsonObj['employee_id']) await setEmployeeId(jsonObj['employee_id'])
                if (jsonObj['az_leave_type']) await setLeaveType(jsonObj['az_leave_type'])
                // if (jsonObj['date_from']) await setDateFrom(jsonObj['date_from'])
                // if (jsonObj['date_to']) await setDateTo(jsonObj['date_to'])
                if (jsonObj['deleted']) await setDeleted(jsonObj['deleted'])
                if (jsonObj['x_state']) await setState(jsonObj['x_state'])
                setTimeout(() => {
                    setListDataDomain(updateListDataDomain(response.id, undefined, jsonObj['az_leave_type'], jsonObj['x_state'], myLeaves, dateFrom, dateTo, deleted, response.is_manager_fr, response.is_back_office_fr));
                }, 500)
            } else {
                setListDataDomain(updateListDataDomain(response.id, undefined, undefined, undefined, undefined, from_date, to_date , undefined, response.is_manager_fr, response.is_back_office_fr));
            }
            setTimeout(() => {
                setPageLoaded(true);
                setHandleChangeSelection(true)
            }, 1000)
        })
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
        if(id){
            let transaction: Leave = {}
            transaction['id'] = parseInt(id)
            setSelectedLeave(transaction)
        }
    }, []);

    // #region status Dropdown
    const [az_state, setState] = useState('');

    const az_state_select = azkSelect({
        compID: "az_state_select",
        modelName: "az.transaction.status",
        valueField: "technical_name",
        dataDomain: `('basic_status', '=', 'true')`,
        titleField: "name",
        allowEmpty: false,
        isSearchable: false,
        defaultValue: az_state,
        onChange: (value) => {
            setState(value)
        },
    });
    // #endregion status Dropdown

    // #region status Dropdown
    const [az_leave_type, setLeaveType] = useState<any>('');
    const [leaveTypeDomain, setLeaveTypeDomain] = useState('');

    const az_type_select = azkSelect({
        compID: "az_type_select",
        modelName: "az.leave.type",
        valueField: "id",
        dataDomain: leaveTypeDomain,
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        onChange: (value) => {
            if (value) setLeaveType(value)
            else setLeaveType('')
        },
        defaultValue: az_leave_type
    });
    // #endregion Type Dropdown

    const [handleChangeSelection, setHandleChangeSelection] = useState<boolean>(true);

    const rejectSelectedLeaves = () => {
        if (selectedData) {
            document.body.classList.add('page-loading')
            axios.put(`${API_URL}/${MODEL_NAME}/${selectedData[0]}/reject_tier_list`, { 'list_ids': selectedData }).then(data => {
                Swal.fire({
                    position: "top-right",
                    title: 'Success!',
                    text: 'Selected leave had been rejected!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
                document.body.classList.remove('page-loading')
            }, err => {
                document.body.classList.remove('page-loading')
            })
        }
    }

    const approveSelectedLeaves = () => {
        if (selectedDataIds) {
            document.body.classList.add('page-loading')
            axios.put(`${API_URL}/${MODEL_NAME}/${selectedDataIds[0]}/validate_tier_list`, { 'list_ids': selectedDataIds }).then(data => {
                Swal.fire({
                    position: "top-right",
                    title: 'Success!',
                    text: 'Selected leave had been approved!',
                    icon: "success",
                    showConfirmButton: false,
                    timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
                document.body.classList.remove('page-loading')
            }, err => {
                document.body.classList.remove('page-loading')
            })
        }
    }

    return (
        <>
            <Row className="m-0 p-0">
                <KTCard className='rounded-0 p-0 custom-input-container'>
                    <Row className="m-0 p-0">
                        {isManger && <>
                            <Col lg="12" md="12" sm="12" xs="12" className="d-flex p-0 m-0">
                                <button disabled={false}
                                    className={`btn btn-light px-2 w-50 rounded-0 ${myLeaves ? "active-custom-tab-btn" : "custom-tab-btn"}`}
                                    type='reset'
                                    onClick={() => {
                                        setMyLeaves(true);
                                        setEmployeeId(undefined)
                                        setListDataDomain(updateListDataDomain(currectEmployee, undefined, az_leave_type, az_state, true, dateFrom, dateTo, deleted, isManger, shopUser))
                                    }
                                    }
                                >My Leave</button>
                                <button disabled={false}
                                    className={`btn btn-light px-2 w-50 rounded-0 ${myLeaves ? "custom-tab-btn" : "active-custom-tab-btn"}`}
                                    type='reset'
                                    onClick={() => {
                                        setMyLeaves(false);
                                        setEmployeeId(undefined);
                                        setListDataDomain(updateListDataDomain(currectEmployee, undefined, az_leave_type, az_state, false, dateFrom, dateTo, deleted, isManger, shopUser))
                                    }}
                                >Team Leaves</button>
                            </Col>
                        </>}
                    </Row>
                </KTCard>
                <KTCard className='rounded-0 custom-input-container'>
                    <div className='card-body p-2'>
                        <div className='row m-0'>
                            <h6 className='py-2'>
                                Manage and submit your leave requests effortlessly
                            </h6>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                {/* begin::Label */}
                                <label className='form-label required'>From</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateFrom(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    }}
                                    defaultValue={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    value={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    max={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6' >
                                {/* begin::Label */}
                                <label className='form-label required'>To</label>
                                <Form.Control
                                    className='p-1'
                                    type='date'
                                    onChange={(event) => {
                                        setDateTo(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    }}
                                    defaultValue={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    value={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                    min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                />
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                {/* begin::Label */}
                                <label className='form-label'>Status</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_state_select}
                                </>)}
                                {/* end::Input */}
                            </div>

                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-6'>
                                {/* begin::Label */}
                                <label className='form-label'>Type</label>
                                {/* end::Label */}

                                {/* begin::Input */}
                                {handleChangeSelection && (<>
                                    {az_type_select}
                                </>)}
                                {/* end::Input */}
                            </div>
                            {!myLeaves && <>
                                <div className='fv-row mb-2 col-lg-12 col-md-12 col-12'>
                                    {/* begin::Label */}
                                    <label className='form-label'>Azadea ID</label>
                                    {/* end::Label */}

                                    {/* begin::Input */}
                                    {handleChangeSelection && (<>
                                        <AzkSelect
                                            compID="azk_temp"
                                            modelName="hr.employee"
                                            valueField="id"
                                            titleField="azadea_employee_name"
                                            dataDomain={`('parent_id','=', ${currectEmployee})`}
                                            allowEmpty={true}
                                            fieldName='employee_id'
                                            onChange={(value) => {
                                                setEmployeeId(parseInt(value))
                                            }}
                                            searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                    </>)}
                                    {/* end::Input */}
                                </div>
                            </>}
                            <div className='fv-row col-lg-12 col-md-12 col-12'>
                                <button type='button' className='btn btn-dark rounded-0 btn-sm' onClick={() => {
                                    setForceRefresh(!forceRefresh)
                                    setListDataDomain(updateListDataDomain(currectEmployee, employeeId, az_leave_type, az_state, myLeaves, dateFrom, dateTo, deleted, isManger, shopUser))
                                }}>
                                    View Result
                                </button>
                            </div>

                        </div>
                    </div>
                </KTCard>
                <hr className="hr m-0" />
                {/* <KTCard className={`rounded-0 py-2 px-0 m-0 custom-KTCard-table ${myLeaves? '':'custom-KTCard-table-with-check'}`} > */}
                <KTCard className={`rounded-0 py-2 px-0 m-0 custom-KTCard-table }`} >

                    <div className='card-body p-2'>
                        <div className='d-flex justify-content-between p-0 m-0'>
                            <h6 className='m-0 pt-2'>
                                <small>Click line to view details</small>
                            </h6>
                            <div className='d-flex justify-content-end p-0 m-0'>
                                {!myLeaves && selectedData && selectedData.length > 0 && <>
                                    {/* <button type='button' className='btn btn-outline btn-color-dark btn-sm m-1' onClick={() => reviewSelectedLeaves}>
                                    Review
                                </button> */}
                                    <button type='button' className='btn btn-outline btn-color-dark btn-sm m-1 rounded-0' onClick={() => rejectSelectedLeaves}>
                                        Reject
                                    </button>
                                    <button type='button' className='btn btn-outline btn-color-dark btn-sm m-1 rounded-0' onClick={() => approveSelectedLeaves()}>
                                        Approve
                                    </button>
                                </>}
                                {myLeaves && <>
                                    <button type='button' className='btn btn-outline btn-color-dark btn-sm rounded-0' onClick={() => navigate(`/app/self-service/leave-submission/new`, { state: {}, replace: true })}>
                                        New
                                        <KTIcon iconName='plus' className='fs-2' />
                                    </button>
                                </>}
                            </div>
                        </div>
                    </div>
                    {table}
                </KTCard>
            </Row>
            <div>
                {selectedLeave && <TransactionViewform leaveId={selectedLeave.id} closeModal={function (): {} {
                    setSelectedLeave(undefined);
                    setForceRefresh(!forceRefresh)
                    return false
                }}></TransactionViewform>}
            </div>
        </>)
}

export default TransactionAppListView;