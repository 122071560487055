import { GrouphModel } from "_azkatech/_models/_group"
import { Response } from "_metronic/helpers"
import { AZKHelper } from "app/modules/components/azkHelper"
import moment from "moment"

export type FinalSettlement = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  template?: string
  x_state?: string
  x_state_view?: string
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean,
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  payroll_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  salary?: string
  as_effective_date?: string
  time_set?: string
  target_as_effective_date?: string
  can_edit?: boolean
  restarted?: boolean
  clearance_trn_id?: any
  salary_day?: number
  notice_period?: number
  leave_salary?: number
  indemnity?: number
  adjustment?: number | 0,
  other_deduction?: number
  other_addition?: number
  ticket_amount_val?: string,
  indemnity_zero?: boolean
  partial_payment?: boolean
  comment?: string
  allowed_paramters?: string | undefined,
  current_approval_ids?: number[]
  need_validation?: boolean,
  marked_file_uploaded?: boolean,
  marked_to_pay_partial?: boolean,
  marked_to_pay?: boolean,


  can_approve?: boolean | false,
  can_reject?: boolean | false,
  can_review?: boolean | false,
  can_review_fs?: boolean | false,
  can_regenerate?: boolean | false,
  can_view?: boolean,
  can_download_pdf?: boolean,
  can_upload_pdf?: boolean,
  can_to_pay?: boolean,
  can_completed?: boolean,
  can_c2p_partial_done?: boolean,
  can_reset_upload?: boolean,
  steps?: [{
    id?: number,
    name?: string
  }]
  serial_number?: any,
  end_nature?: any,
  joining_date?: any,
  indem_start_date?: any,
  actual_termination_date?: string
  end_date?: string,
  basic_salary?: number,
  housing?: number,
  other_allowance?: number,

  working_days?: any,
  salary_days?: any,
  housing_days?: any,
  transportation_days?: any,
  commission?: any,

  notice_period_days?: any,
  salary_notice_period?: any,
  housing_notice_period?: any,
  transportation_notice_period?: any,

  leave_days?: any,
  leave_amount?: any,

  indemnity_days?: any,
  indemnity_years?: any,
  indemnity_amount?: any,
  deductions?: [{
    element_id?: any,
    amount?: number
  }]

  other_addition_amount?:string,
  other_deduction_amount?:string,
  hrms_status?: string,
  net_to_pay?: string,
  currency_name?: string,
  bankname?: string,
  iban_number?: string
  swift_code?: string,
  bankcode?: any,
  remark?: string,
  fs_letter?: string,
  date_group_start_date?: string,

  eos_extra_hours?: string,
  eos_public_holiday?: string,
  ticket_amount?: string,

  proposed_salary_n?: any,
  housing_all?: any,
  trans_all?: any,
  monthly_working_days?: any,
  eos_settlement?: any,
  annual_leave_settlement?: any,
  ticket_encashment_settlement?: any,
  commission_settlement?: any,
  paid_amount1?: any,
  due_date1?: any,
  paid_amount2?: any,
  due_date2?: any,
  indemnity_year?: any,
  nationality?: any,
  job_name?: any,
}

export const initialTransaction: FinalSettlement = {
  name: '',
  as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
  target_as_effective_date: moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
}

export type FinalSettlementQueryResponse = Response<Array<FinalSettlement>>

export const initialTransferCompany: FinalSettlement = {

}
