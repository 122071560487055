import { Response } from "_metronic/helpers"

export type AttendanceAdjustmentLine = {
  id: number
  date?: string | ''
  hour?: string
  min?: string
  adjustment_type?: string
}

export type AttendanceAdjustment = {
  id?: number
  name?: string
  employee_id?: any
  type_id?: number
  x_state?: string
  x_state_view?: string
  can_review?: boolean
  can_delete?: boolean
  can_write?: boolean
  validated?: boolean,
  rejected?: boolean,
  state?: string
  az_country_id?: any
  az_company_id?: any
  brand_id?: any
  azadea_id?: number
  azadea_employee_name?: string
  branch_id?: any
  section_id?: any
  grade_id?: any
  job_id?: any
  time_set?: string
  can_edit?: boolean
  restarted?: boolean
  adjustment_reason_id?: any
  create_date?: any
  comment?: string
  line_ids: AttendanceAdjustmentLine[]
  is_reverse?: boolean
}


export type SystemAttendanceLine = {
  businessGroupId?: string
  countryName?: string
  securityProfileId?: string
  azadeaId?: string
  employeeName?: string
  companyId?: string
  companyName?: string
  organizationId?: string
  organizationName?: string
  brandCode?: string
  brandName?: string
  attendanceType?: string
  attendanceDate?: string
  attendanceHour?: string
  attendanceMinute?: string
  employeeBusinessUnit?: string
  employeeBranchCode?: string
  punchBranchCode?: string
  punchBusinessUnit?: string
  punchOrganizationName?: string
  entryType?: string
  message?: string
}

export type SystemSummaryAttendanceLine = {
  businessGroupId?: string
  countryName?: string
  azadeaId?: string
  employeeName?: string
  companyName?: string
  organizationName?: string
  atsPunch?: number
  manualPunch?: number
  groupByValues?: any
}

export type SystemDailyAttendanceLine = {
  businessGroupId?: string
  countryName?: string
  brandCode?: string
  brandName?: string
  azadeaId?: string
  employeeName?: string
  companyName?: string
  organizationName?: string
  transationName?: string
  transactionDate?: any
  totalHours?: number
}
export const initialTransfaction: AttendanceAdjustment = {
  name: undefined,
  line_ids: [
    {
      date: '', hour: undefined, min: undefined, adjustment_type: undefined,
      id: 0
    },
    // Add more initial values for additional lines
  ]
}

export type AttendanceAdjustmentQueryResponse = Response<Array<AttendanceAdjustment>>

