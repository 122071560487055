class AZKHelper {

  static BACKEND_DATE_FORMAT = 'YYYY-MM-DD HH:mm:SS'
  static BACKEND_DATE_FORMAT_SEARCH = 'YYYY-MM-DDTHH:mm:SS'
  static FRONTEND_DATE_FORMAT = 'yyyy-MM-DD'
  static FRONTEND_TIME_FORMAT = 'HH:mm'
  static REPORT_DATE_FORMAT = 'DD MMM YYYY'
  static FRONTEND_DATE_MONTH_FORMAT = 'yyyy-MM'
  static FRONTEND_DATE_FULL_MONTH_FORMAT = 'yyyy-MMMM'
  static FRONTEND_DATE_MONTH_ONLY_FORMAT = 'MMMM'
  static FRONTEND_DATE_YEAR_ONLY_FORMAT = 'yyyy'
  static FRONTEND_TABLE_DATE_FORMAT = 'DD/MMM/YYYY'
  static NOTIFICATION_FETCH_PERIOD = 120000
  static SWAL_TOAST_TIMER = 5000
  static APP_SWAL_TOAST_TIMER = 2000
  static SUCCESS_SWAL_TOAST_TIMER = 1000
  static ALLOWED_ATTACHMENT_TYPE = 'image/*,.pdf' // JPEG/JPG PNG GIF WebP  TIFF BMP
  static ALLOWED_DOC_TYPE = '.pdf' // JPEG/JPG PNG GIF WebP  TIFF BMP
  static ALLOWED_ATTACHMENT_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff', 'image/bmp', 'application/pdf'];
  static ALLOWED_DOC_TYPES = ['application/pdf'];
  static READ_ACTION = 'read'
  static WRITE_ACTION = 'write'
  static DELETE_ACTION = 'unlink'
  static CREATE_ACTION = 'create'
  static APP_VERSION = '2.0.0'
  static TRANSACTIONTYPE = {
    AZ_TRN_ADDITION_DEDUCTION: 'az.trn.addition.deduction',
    AZ_TRN_CHANGE_EMPLOYMENT_CATEGORY: 'az.trn.change.employment.category',
    AZ_TRN_MANAGER_ASSIGNMENT: 'az.trn.manager.assignment',
    AZ_TRN_CLEARANCE: 'az.trn.clearance',
    AZ_TRN_DEMOTION: 'az.trn.demotion',
    AZ_TRN_FINAL_SETTLEMENT: 'az.trn.final.settlement',
    AZ_TRN_PROMOTION: 'az.trn.promotion',
    AZ_TRN_SALARY_STRUCTURE: 'az.trn.salary.structure',
    AZ_SALARY_STRUCTURE_SETTING: 'az.salary.structure.setting',
    AZ_TRN_SEPARATION: 'az.trn.separation',
    AZ_TRN_TRANSFER_COMPANY: 'az.trn.transfer.company',
    AZ_TRN_TRANSFER_WITHIN_COMPANY: 'az.trn.transfer.within.company',
    AZ_TRN_TRANSACTION_MENU: 'az.trn.transaction.menu',
    AZ_TRN_ALL_TRANSACTION_VIEW: 'az.all.transaction.view',
    AZ_CHART_TRANSACTION_STATUS: 'az.transaction.status.chart',
    AZ_CHART_SUBMIT_DURATION: 'az.transaction.submit.duration.chart',
    AZ_CHART_FS: 'az.final.settelement.chart',
    AZ_TRN_SELF_SERVICE_MENU: 'az.trn.self.service.menu',
    AZ_TRN_LEAVE: 'az.trn.leave',
    AZ_TRN_PAYSLIP: 'az.trn.payslip',
    AZ_TRN_SELF_SERVICE: 'az.trn.self.service',
    AZ_TRN_ATTENDANCE_ADJUSTMENT: 'az.trn.attendance.adjustment',
    AZ_TRN_EXIT_INTERVIEW: 'az.trn.exit.interview.request',
    AZ_TRN_TRAVEL_REQUEST: 'az.trn.travel.request',
    AZ_TRN_TRAVEL_PER_DEIM: 'az.trn.travel.per.diem',
  }
  static PIN_CODE_LOCAL_STORAGE_KEY= 'azk-pin-code-hr-lite'

  // V2
  static PATERNITY_LEAVE_CODE = 'paternity_leave'
  static MATERNITY_LEAVE_CODE = 'maternity_leave'
  static BUSINESS_TRAVEL_LEAVE_CODE = 'business_travel'
  static BEREAVEMENT_LEAVE_CODE = 'bereavement_leave'
  static MEDICAL_LEAVE_CODE = 'medical_leave' 
  static HALF_DAY = 'half_day_leave' 
  
  // static PATERNITY_LEAVE_CODE = '1'
  // static MATERNITY_LEAVE_CODE = '1'
  // static BUSINESS_TRAVEL_LEAVE_CODE = '1'
  // static BEREAVEMENT_LEAVE_CODE = '1'
  // static MEDICAL_LEAVE_CODE = '1' 
  static INPUT_TEXT_FIELD = 'text'
  static INPUT_DATE_FIELD = 'date'
  static INPUT_DROPDOWN_FIELD = 'dropdown'
  static INPUT_FREE_DROPDOWN_FIELD = 'free_dropdown'
  static INPUT_NOMBER_FIELD = 'number'
  static INPUT_RADIO_BUTTON_FIELD = 'radiobutton'
  static INPUT_CHECK_BOX_FIELD = 'checkbox'
  static INPUT_RATE_FIELD = 'rate'
  static PIN_CODE_KEY = "Gj3EOPgYo/FuokRDnsex2eOhuu3h3QGHie1DnxOIWpwZWu2daA2Ks7P9823018g9"


  }

  export {AZKHelper}