import { EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import * as Yup from 'yup'
import { Payslip, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { PutPayslip } from "./_requests";
import moment from "moment";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router";
import { AZKHelper } from "app/modules/components/azkHelper";
import { useParams } from "react-router-dom";
import { GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import ReactDOM from 'react-dom';
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import { HeadeRequestAPPPinMenu } from "_metronic/partials/layout/header-menus/HeadeRequestAPPPinMenu";
import { AsyncPaginate } from "react-select-async-paginate";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    payslip_month: Yup.string().required('Month from is required'),
    payslip_year: Yup.string().required('Year from is required')
})


const TransactionAPPform: FC<Props> = () => {
    const { id } = useParams();
    const formRef = useRef<FormikProps<Payslip>>(null);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [payslip, setPayslip] = useState<Payslip>();
    const [needPinCode, setNeedPinCode] = useState<boolean>(false);

    const handleEmployeeChange = async function (userId): Promise<{}> {
        document.body.classList.add('page-loading')
        if (userId) {
            setEmployee(undefined)
            GetEmployeeByUserId(userId).then(response => {
                if (response) {
                    setEmployee(response)
                    formRef.current?.setValues({
                        employee_id: response.id,
                        payslip_month: moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT),
                        payslip_year: moment().format(AZKHelper.FRONTEND_DATE_YEAR_ONLY_FORMAT)
                    })
                    setMonth(moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT))
                    setYear(moment().format(AZKHelper.FRONTEND_DATE_YEAR_ONLY_FORMAT))
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
            })
        }
        return true
    }

    const checkPinCodeAvailablity = () => {
        let lsPinValue = localStorage.getItem(AZKHelper.PIN_CODE_LOCAL_STORAGE_KEY)
        if (lsPinValue) {
            const jsonObj = JSON.parse(lsPinValue)
            if (jsonObj['pin_code']) {
                setNeedPinCode(false)
                return true
            } else {
                setNeedPinCode(true)
                return false
            }
        } else {
            setNeedPinCode(true)
            return false
        }
    }

    useEffect(() => {
        formRef.current?.resetForm()
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                handleEmployeeChange(auth.uid)
            }
            checkPinCodeAvailablity()
        }
    }, [id])


    const onSubmitHandler = async (values: Payslip, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        const pinCodeAvailabl = await checkPinCodeAvailablity()
        if (pinCodeAvailabl) {
            if (formRef.current?.isValid) {
                document.body.classList.add('page-loading')
                PutPayslip(employee?.id, month, year).then(date => {
                    setPayslip(date)
                    document.body.classList.remove('page-loading')
                }, e => {
                    if (e.message.indexOf('Pin code not valid!') >= 0) {
                        setNeedPinCode(true)
                    }
                })
            } else {
                actions.setSubmitting(false)
                formRef.current?.validateForm().then(errors => {
                    if (Object.keys(errors).length > 0) {
                        console.log(errors)
                    }
                });
            }
        }
    }

    const getTargetElement = () => document.getElementById('content-id');
    const generate_report = async (fs) => {
        let to_pay_itme: any = []
        let total_to_pay = 0;
        let total_to_pay_YTD = 0;
        let deduction_itme: any = []
        let total_deduction = 0;
        let total_deduction_YTD = 0;
        let medical_itme: any = []
        let medical_deduction_itme: any = []
        let NFFS_itme: any = []
        let total_NFFS = 0;
        let total_NFFS_YTD = 0;
        await payslip?.details?.map((detail, index) => {
            if (detail.type === 'Employee Pay Type') {
                if (detail?.amount && detail?.amountYTD) {
                    total_to_pay += parseFloat(detail?.amount)
                    total_to_pay_YTD += parseFloat(detail?.amountYTD)
                }
                to_pay_itme.push(<tr>
                    <td width="40%" align="left">{detail.label}</td>
                    {detail?.amount &&
                        <td width="30%" align="right">
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="30%" align="right">0.00</td>
                    }
                    {detail?.amountYTD &&
                        <td width="30%" align="right">
                            {detail.amountYTD && parseFloat(detail?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amountYTD &&
                        <td width="30%" align="right">0.00</td>
                    }
                </tr>)
            }
            if (detail.type === 'Employee Deductions') {
                if (detail?.amount && detail?.amountYTD) {
                    total_deduction += parseFloat(detail?.amount)
                    total_deduction_YTD += parseFloat(detail?.amountYTD)
                }
                deduction_itme.push(<tr>
                    <td width="40%" align="left">{detail.label}</td>
                    {detail?.amount &&
                        <td width="30%" align="right">
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="30%" align="right">0.00</td>
                    }
                    {detail?.amountYTD &&
                        <td width="30%" align="right">
                            {detail.amountYTD && parseFloat(detail?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amountYTD &&
                        <td width="30%" align="right">0.00</td>
                    }
                </tr>)
            }
            if (detail.type === 'NSSF') {
                if (detail?.amount && detail?.amountYTD) {
                    total_NFFS += parseFloat(detail?.amount)
                    total_NFFS_YTD += parseFloat(detail?.amountYTD)
                }
                NFFS_itme.push(<tr>
                    <td width="40%" align="left">{detail.label}</td>
                    {detail?.amount &&
                        <td width="30%" align="right">
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="30%" align="right">0.00</td>
                    }
                    {detail?.amountYTD &&
                        <td width="30%" align="right">
                            {detail.amountYTD && parseFloat(detail?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    }{!detail?.amountYTD &&
                        <td width="30%" align="right">0.00</td>
                    }
                </tr>)
            }
        })
        await payslip?.subDetails?.map((detail, index) => {
            if (detail.type === 'Employee Pay Type') {
                // if (detail?.amount){
                //     total_to_pay += parseFloat(detail?.amount)
                // }
                medical_itme.push(<tr>
                    <td width="75%" align="left" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: 10 }}>{detail.label}</td>
                    {detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>0.00</td>
                    }
                </tr>)
            }
            if (detail.type === 'Employee Deductions') {
                // if (detail?.amount) {
                //     total_deduction += parseFloat(detail?.amount)
                // }
                medical_deduction_itme.push(<tr>
                    <td width="75%" align="left" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingLeft: 10 }}>{detail.label}</td>
                    {detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>
                            {detail.amount && parseFloat(detail?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                        </td>
                    }{!detail?.amount &&
                        <td width="25%" align="right" className="box-container-boarder small-padding" style={{ border: '1px solid black', borderCollapse: 'collapse', paddingRight: 10 }}>0.00</td>
                    }
                </tr>)
            }
        })
        return (
            <div style={{ padding: 20 }}>
                <table width="100%" style={{ padding: 10 }}>
                    <tr>
                        <td align="center" width="100%"><h2><span>{payslip?.companyName}</span>&nbsp;&nbsp;&nbsp;<span>Pay Slip </span><span>{year + ' - ' + month}</span></h2></td>
                    </tr>
                </table>
                <table width="100%" style={{ padding: 10, border: '0px solid black', borderCollapse: 'collapse' }}>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="13%" align="left"><strong>Id</strong></td>
                        <td width="20%" align="left">{employee?.azadea_id}</td>
                        <td width="13%" align="left"><strong>Employee</strong></td>
                        <td width="20%" align="left">{payslip?.fullName}</td>
                        <td width="13%" align="left"><strong>Annual Leave</strong></td>
                        <td width="20%" align="left">{payslip?.annualLeaveBalance}</td>
                    </tr>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="13%" align="left"><strong>Branch</strong></td>
                        <td width="20%" align="left">{payslip?.branchName}</td>
                        <td width="13%" align="left"><strong>Start Date</strong></td>
                        <td width="20%" align="left">{payslip?.startDate}</td>
                        <td width="13%" align="left"><strong>Comm Hours</strong></td>
                        <td width="20%" align="left">{payslip?.commissionHours}</td>
                    </tr>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="13%" align="left"><strong>Title</strong></td>
                        <td width="20%" align="left">{payslip?.title}</td>
                        <td width="13%" align="left"><strong>Status</strong></td>
                        <td width="20%" align="left">{payslip?.maritalStatus}</td>
                        <td width="13%" align="left"><strong>Non Comm</strong></td>
                        <td width="20%" align="left">{payslip?.nonCommissionHours}</td>
                    </tr>
                    <tr style={{ border: '0px soild #000' }} >
                        <td width="13%" align="left"><strong>Nationality</strong></td>
                        <td width="20%" align="left">{payslip?.nationality}</td>
                        <td width="13%" align="left"><strong>Grade</strong></td>
                        <td width="20%" align="left">{payslip?.grade}</td>
                        <td width="13%" align="left"><strong>Paid Hours</strong></td>
                        <td width="20%" align="left">{payslip?.paidHours}</td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" className="box-container-boarder-no-bottom" style={{ border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse' }}>
                    <tr className="box-container-boarder-no-bottom" style={{ border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse' }}>
                        <td className="box-container-boarder-no-bottom custom-align-items-start" width="50%" style={{ paddingInline: 10, border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse', alignContent:'start' }}>
                            <table width="100%" style={{ padding: 10 }}  >
                                <tr>
                                    <td width="40%"><b>Employee Pay Type</b></td>
                                    <td width="30%" align="right"><b>{year + ' - ' + month}</b></td>
                                    <td width="30%" align="right"><b>Year to Date</b></td>
                                </tr>
                                {to_pay_itme?.map(item => {
                                    return item
                                })}
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder-no-bottom custom-align-items-start" style={{ paddingInline: 10, border: '1px solid black', borderBottom: '0px solid black', borderCollapse: 'collapse', alignContent:'start'}}>
                            <table width="100%" style={{ padding: 10 }} >
                                <tr>
                                    <td width="40%"><b>Employee Deductions</b></td>
                                    <td width="30%" align="right"><b>{year + ' - ' + month}</b></td>
                                    <td width="30%" align="right"><b>Year to Date</b></td>
                                </tr>
                                {deduction_itme?.map(item => {
                                    return item
                                })}
                                <tr>
                                    <td width="40%"><b>Total Employee Deductions</b></td>
                                    <td width="30%" align="right">
                                        {total_deduction && total_deduction?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td width="30%" align="right">
                                        {total_deduction_YTD && total_deduction_YTD?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                                <tr style={{ backgroundColor: '#' }} className="net-salary-tr">
                                    <td width="40%"><b>Net Salary</b></td>
                                    <td width="30%" align="right">
                                        {payslip?.amount && parseFloat(payslip?.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                    <td width="30%" align="right">
                                        {payslip?.amountYTD && parseFloat(payslip?.amountYTD)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </td>
                                </tr>
                                {NFFS_itme && NFFS_itme.length > 0 &&<>
                                    <tr>
                                        <td width="40%"><b>NSSF Company Contribution</b></td>
                                        <td width="30%" align="right"><b>{year + ' - ' + month}</b></td>
                                        <td width="30%" align="right"><b>Year to Date</b></td>
                                    </tr>
                                </>}
                                {NFFS_itme?.map(item => {
                                    return item
                                })}
                            </table>
                        </td>
                    </tr>
                    <tr >
                        <td width="50%" className="box-container-boarder-no-top" style={{ paddingInline: 10, border: '1px solid black', borderTop: '0px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%" style={{ padding: 10 }} >
                                <tr>
                                    <td width="40%"><b>Gross Income</b></td>
                                    <td width="30%" align="right"><b>
                                        {total_to_pay?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                    <td width="30%" align="right"><b>
                                        {total_to_pay_YTD?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder-no-top" style={{ paddingInline: 10, border: '1px solid black', borderTop: '0px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%" style={{ padding: 10 }} >
                                <tr>
                                    <td width="40%"><b>Total Company Contribution</b></td>
                                    <td width="30%" align="right"><b>
                                        {total_NFFS?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                    <td width="30%" align="right"><b>
                                        {total_NFFS_YTD?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                    </b></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" className="box-container-boarder" style={{ padding: 10, border: '1px solid black', borderCollapse: 'collapse' }}>
                    <tr className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        <td className="box-container-boarder text-center" align={"center"} width="100%" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <b>Description</b>
                        </td>
                    </tr>
                </table>
                <br />
                <br />
                <table width="100%" style={{ padding: 10 }}  >
                    <tr>
                        <td width="100%">
                            <table width="100%" className="boarder-style" style={{ padding: 10, borderSpacing: 0, borderCollapse: 'collapse' }}  >
                                <tr>
                                    <td width="49%" className="box-container-boarder text-center" align={"center"} style={{ border: '1px solid black', borderCollapse: 'collapse' }}><b>Medical Insurance Contribution</b></td>
                                    <td width="2%"></td>
                                    <td width="49%" className="box-container-boarder text-center" align={"center"} style={{ border: '1px solid black', borderCollapse: 'collapse' }}><b>Medical Insurance Deduction</b></td>
                                </tr>
                                <tr style={{ padding: 0, borderSpacing: 0, borderCollapse: 'collapse', alignContent:'start' }}>
                                    <td width="49%" className="custom-align-items-start zero-padding" style={{padding:'0px', margin:'0px', alignContent:'start'}}>
                                        <table width="100%" className="box-container-boarder" style={{ padding: 0, borderSpacing: 0, borderCollapse: 'collapse', alignContent:'start' }} >
                                            {medical_itme?.map(item => {
                                                return item
                                            })}
                                        </table>
                                    </td>
                                    <td width="2%"></td>
                                    <td width="49%" className="custom-align-items-start zero-padding" style={{padding:'0px', margin:'0px', alignContent:'start'}}>
                                        <table width="100%" className="box-container-boarder" style={{ padding: 0, borderSpacing: 0, borderCollapse: 'collapse', alignContent:'start' }} >
                                            {medical_deduction_itme?.map(item => {
                                                return item
                                            })}
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
        )
    }

    const downloadPayslip = () => {
        if (payslip) {
            document.body.classList.add('page-loading')
            let html_obj = generate_report(payslip)
            html_obj.then(async html_obj => {
                if (html_obj) {
                    const options = {
                        // method: 'open', // 'save' or 'open'
                        resolution: Resolution.HIGH,
                        page: {
                            margin: Margin.SMALL,
                            format: 'A4',
                            //   orientation: 'landscape',
                        },
                        canvas: {
                            //   mimeType: 'image/png',
                            qualityRatio: 1,
                        },
                        overrides: {
                            pdf: { compress: false },
                            canvas: { useCORS: true },
                        },
                    };
                    const parentElement = document.createElement('div');
                    parentElement.style.cssText = 'width: 1240px !important; background-color: #e5e5e5 !important'
                    const element =
                        <div style={{ width: '1240px', background: '#f8bb86' }}>
                            <button onClick={() => generatePDF(getTargetElement, options)} id="export_data_pdf">Generate PDF</button>
                            <div id="content-id" style={{ width: '1240px' }}>
                                {html_obj}
                            </div>
                        </div>
                    ReactDOM.render(element, parentElement);
                    document.body.appendChild(parentElement)
                    const inputElement = document.querySelector('#export_data_pdf') as HTMLInputElement
                    inputElement.click()
                    document.body.removeChild(parentElement)
                    document.body.classList.remove('page-loading')
                    document.body.classList.remove('page-loading')
                }
            })
        }
    }


    const closePinCodeModal = () => {
        setNeedPinCode(false)
        formRef.current?.submitForm()
    }

    // Month selection
    const [selectedMonthOption, setSelectedMonthOption] = useState<any>({value: moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT).toLowerCase(), label: moment().format(AZKHelper.FRONTEND_DATE_MONTH_ONLY_FORMAT)});
    const [month, setMonth] = useState<any>();
    const loadMonthOptions = async (search, loadedOptions) => {
        const options = [
            {value: 'january', label: 'January'},
            {value: 'february', label: 'February'},
            {value: 'march', label: 'March'},
            {value: 'april', label: 'April'},
            {value: 'may', label: 'May'},
            {value: 'june', label: 'June'},
            {value: 'july', label: 'July'},
            {value: 'august', label: 'August'},
            {value: 'september', label: 'September'},
            {value: 'october', label: 'October'},
            {value: 'november', label: 'November'},
            {value: 'december', label: 'December'}
        ];
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };
    const handleOnMonthChange = (selectedOption) => {
        setSelectedMonthOption(selectedOption)
        setMonth(selectedOption.label)
        formRef.current?.setFieldValue('payslip_month', selectedOption.value);

    };
    // End Month selection

    // Month selection
    const [selectedYearOption, setSelectedYearOption] = useState<any>({value: new Date().getUTCFullYear(), label: new Date().getUTCFullYear()});
    const [year, setYear] = useState<any>();
    const [lastLoadedYear, setLastLoadedYear] = useState<any>(new Date().getUTCFullYear());
    const loadYearOptions = async (search, loadedOptions) => {
        const options:any[] = []
        for (let index = 0; index < 10; index++) {
            options.push({value: lastLoadedYear - index, label: lastLoadedYear - index})
        }
        setLastLoadedYear(lastLoadedYear -10)
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: lastLoadedYear > 2000,
        };
    };
    const handleOnYearChange = (selectedOption) => {
        setSelectedYearOption(selectedOption)
        setYear(selectedOption.value)
        formRef.current?.setFieldValue('payslip_year', selectedOption.value);
    };
    // End Year selection

    return (
        <>
            <Row className="m-0">
                <Col md='12' lg='12' xl='12' className="p-0">
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            return (
                                <>
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset>
                                            {!payslip && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Row className="m-0 my-2">
                                                        <h6>
                                                            Select the month and year to view your payslip
                                                        </h6>
                                                        <Col lg="4" md="12" sm="12" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Month
                                                                </Form.Label>
                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                    placeholder="Select an option"
                                                                    loadOptions={loadMonthOptions}
                                                                    onChange={handleOnMonthChange}
                                                                    defaultValue={selectedMonthOption}
                                                                    value={selectedMonthOption}
                                                                    id='payslip_month' name='payslip_month'

                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='payslip_month' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="12" sm="12" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label className='required'>
                                                                    Year
                                                                </Form.Label>
                                                                <AsyncPaginate className='rounded-0 col-12'
                                                                    placeholder="Select an option"
                                                                    loadOptions={loadYearOptions}
                                                                    onChange={handleOnYearChange}
                                                                    defaultValue={selectedYearOption}
                                                                    value={selectedYearOption}
                                                                    id='payslip_year' name='payslip_year'
                                                                />
                                                                <div className='text-danger'>
                                                                    <ErrorMessage name='payslip_year' />
                                                                </div>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="8" md="8" sm="6" xs="12" className="d-flex justify-content-end py-2">
                                                            <button
                                                                title="SUBMIT"
                                                                type='submit'
                                                                className='btn btn-dark rounded-0 btn-sm col-12'
                                                            >
                                                                <span className='indicator-label'>SUBMIT</span>
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && payslip && (
                                            <>
                                                <Row className="m-0 card">
                                                    <h6 className="py-2 px-6 m-0 card-header custom-card-header justify-content-center">
                                                        Payslip {year + ' - ' + month}</h6>
                                                    <Row className="m-0 my-2 p-0 pt-2" style={{ backgroundColor: '#F6F6F6' }}>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="payslip-form-label">
                                                                    Id
                                                                </Form.Label>
                                                                <Form.Label className="payslip-form-label-value">
                                                                    {employee.azadea_id}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="payslip-form-label">
                                                                    Employee
                                                                </Form.Label>
                                                                <Form.Label className="payslip-form-label-value">
                                                                    {payslip.fullName}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="payslip-form-label">
                                                                    Annual Leave
                                                                </Form.Label>
                                                                <Form.Label className="payslip-form-label-value">
                                                                    {payslip.annualLeaveBalance}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                <Form.Label className="payslip-form-label">
                                                                    Paid Hours
                                                                </Form.Label>
                                                                <Form.Label className="payslip-form-label-value">
                                                                    {payslip.paidHours}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>


                                                        <Col lg="4" md="4" sm="6" xs="12" className="p-0">
                                                            <Form.Group className='my-2 py-3 d-flex justify-content-between px-2' style={{ backgroundColor: '#5F5F5F' }}>
                                                                <Form.Label className="p-0 m-0 text-white">
                                                                    <b>DESCRIPTION</b>
                                                                </Form.Label>
                                                                <Form.Label className="p-0 m-0 text-white">
                                                                    <b>AMOUNT</b>
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        {payslip?.details?.filter(item => item.type === 'Employee Pay Type')?.map(item => {
                                                            if (item?.amount && parseFloat(item?.amount) != 0) {
                                                                return (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                            <Form.Label className="payslip-form-label">
                                                                                {item.label}
                                                                            </Form.Label>
                                                                            <Form.Label className="payslip-form-label-value">
                                                                            {item.amount && parseFloat(item.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col></>)
                                                            }
                                                        })}
                                                        {payslip?.details?.filter(item => item.type === 'Employee Deductions')?.map(item => {
                                                            if (item?.amount && parseFloat(item?.amount) != 0) {
                                                                return (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                            <Form.Label className="payslip-form-label">
                                                                                {item.label}
                                                                            </Form.Label>
                                                                            <Form.Label className="payslip-form-label-value">
                                                                            {item.amount && parseFloat(item.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col></>)
                                                            }
                                                        })}
                                                        {payslip?.details?.filter(item => item.type === 'NSSF')?.map(item => {
                                                            if (item?.amount && parseFloat(item?.amount) != 0) {
                                                                return (<>
                                                                    <Col lg="4" md="4" sm="6" xs="12">
                                                                        <Form.Group className='mb-2 d-flex justify-content-between'>
                                                                            <Form.Label className="payslip-form-label">
                                                                                {item.label}
                                                                            </Form.Label>
                                                                            <Form.Label className="payslip-form-label-value">
                                                                            {item.amount && parseFloat(item.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                            </Form.Label>
                                                                        </Form.Group>
                                                                    </Col></>)
                                                            }
                                                        })}
                                                        <Col lg="4" md="4" sm="6" xs="12" className="p-0">
                                                            <Form.Group className='py-2 d-flex justify-content-between px-2' style={{ backgroundColor: '#E6E6E6' }}>
                                                                <Form.Label className="p-0 m-0 text-black">
                                                                    <b>NET SALARY</b>
                                                                </Form.Label>
                                                                <Form.Label className="p-0 m-0 text-black">
                                                                    {payslip.amount && parseFloat(payslip.amount)?.toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 my-2 d-flex justify-content-end">
                                                        <div className="d-flex justify-content-between">
                                                            <button
                                                                title="Download as PDF"
                                                                type='reset'
                                                                onClick={() => downloadPayslip()}
                                                                className='btn btn-dark rounded-0 btn-sm col-5'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <span className='indicator-label'>Download as PDF</span>
                                                            </button>
                                                            <button
                                                                title="Discard"
                                                                type='reset'
                                                                onClick={() => setPayslip(undefined)}
                                                                className='btn btn-dark rounded-0 btn-sm col-5'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <span className='indicator-label'>Discard</span>
                                                            </button>

                                                        </div>
                                                    </Row>
                                                </Row>
                                            </>
                                        )}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col >
            </Row>
            {needPinCode && <HeadeRequestAPPPinMenu closeModal={function (): {} {
                setNeedPinCode(false)
                return closePinCodeModal
            }} />}
        </>
    )
}

export default TransactionAPPform;