import { useState, useEffect } from 'react';
import axios from 'axios';
import azkSelect from "_azkatech/azkSelect"
import { useNavigate } from 'react-router';
import { KTCard, KTCardBody, KTIcon } from '_metronic/helpers'
import { API_URL } from '_azkatech/azkHelper';
import * as authHelper from 'app/modules/auth/core/AuthHelpers'
import { Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { AZKHelper } from 'app/modules/components/azkHelper';
import { AuthModel } from 'app/modules/auth';
import { CSVLink } from 'react-csv'
import ReactDOM from 'react-dom';
import DataTable from 'react-data-table-component';
import { SystemSummaryAttendanceLine } from './model';
import { GetEmployeeByUserId } from 'app/modules/components/employeeDetail/_requests';
import AzkSelect from '_azkatech/azkSelect';

export const transactionModle = {
    transfer_company: 'az.trn.attendance.adjustment',
};

const SummaryReportView = () => {
    const navigate = useNavigate()
    const MODEL_NAME = "az.trn.attendance.adjustment"

    const [selectedExcelData, setSelectedExcelData] = useState<any[]>([]);
    const [userId, setUserId] = useState<number>();
    const [currectEmployee, setCurrectEmployee] = useState<number>();
    const [dateFrom, setDateFrom] = useState<any>();
    const [dateTo, setDateTo] = useState<any>();
    const [employeeId, setEmployeeId] = useState<number>()
    const [groupByCountry, setGroupByCountry] = useState<boolean>(false)
    const [groupByCompany, setGroupByCompany] = useState<boolean>(false)
    const [groupByBrand, setGroupByBrand] = useState<boolean>(false)
    const [groupByShop, setGroupByShop] = useState<boolean>(false)
    const [groupByAzadeaId, setGroupByAzadeaId] = useState<boolean>(false)
    const [systemData, setSystemData] = useState<SystemSummaryAttendanceLine[]>()
    const [systemColumns, setSystemColumns] = useState<any[]>();
    const [offset, setOffset] = useState(1);
    const [perPage, setPerPage] = useState(9999);
    const [totalRows, setTotalRows] = useState(perPage);
    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const [employeeDomainReady, setEmployeeDomainReady] = useState<boolean>(false);
    const [isManger, setIsManger] = useState<boolean>(false);
    const [superManager, setSuperManager] = useState<boolean>(false);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [dataReqeusted, setDataReqeusted] = useState<boolean>(false);
    // #region Transfer List as DataTable

    const viewSystemResult = async (pageNumber, itemPerPage) => {
        setDataReqeusted(true)
        if ((groupByCountry || groupByBrand || groupByCompany || groupByShop || groupByAzadeaId) && az_country && dateFrom && moment(dateFrom).isValid() && dateTo && moment(dateTo).isValid()) {
            fillSystemColumns()
            let remoteData = []
            document.body.classList.add('page-loading')
            try {
                const date_from = moment(dateFrom).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
                const date_to = moment(dateTo).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
                let groupByArray: string[] = []
                if (groupByCountry) groupByArray.push('countryName')
                if (groupByCompany) groupByArray.push('companyName')
                if (groupByBrand) groupByArray.push('brandcode')
                if (groupByShop) groupByArray.push('organizationName')
                if (groupByAzadeaId) groupByArray.push('azadeaId')
                const groupby = groupByArray.join(',')
                let entryType = null;
                if (az_adjustment_type) entryType = az_adjustment_type?.value
                const result = await axios.put(`${API_URL}/hr.employee/${currectEmployee}/search_attendence_system_records_summary_report?fromDate=${date_from}&toDate=${date_to}&azadeaId=${employeeId}&country=${az_country}&company=${az_company}&brand=${az_brand}&shop=${az_branch}&pageNumber=${pageNumber}&pageSize=${itemPerPage}`, { 'fromDate': date_from, 'toDate': date_to, 'azadeaId': employeeId, 'country': az_country, 'company': az_company, 'brand': az_brand, 'shop': az_branch, 'pageNumber': pageNumber, 'pageSize': itemPerPage, 'group_by': groupby })
                remoteData = result.data ? result.data : []
                if (result.data?.hasNext) setTotalRows(itemPerPage * pageNumber + itemPerPage)
                document.body.classList.remove('page-loading')
            } catch (error) {
                console.error(error);
                document.body.classList.remove('page-loading')
            }
            if (remoteData)
                setSystemData(remoteData);
            setDataReqeusted(false)
        }
    }

    const handleEditBtnClick = (id: any) => {
        navigate(`/self-service/attendance/${id}/view`, { state: {}, replace: true });
    }


    const fillSystemColumns = () => {
        let systemColumnList: any[] = []
        systemColumnList.push({
            name: 'Country',
            selector: row => row.countryName ? row.countryName : undefined,
            sortable: false,
            tech_name: 'countryName',
            cell: (d) => [(<>
                <Row className='fs-8 m-0 fw-bold text-gray-600'>
                    {d.countryName}
                </Row>
            </>)]
        })
        if (groupByCompany)
            systemColumnList.push({
                name: 'Company',
                selector: row => undefined,
                sortable: false,
                tech_name: 'companyName',
                cell: (d) => [(<>
                    <Row className='fs-8 m-0 fw-bold text-gray-600'>
                        {d.groupByValues['COMPANYNAME']}
                    </Row>
                </>)]
            })
        if (groupByBrand)
            systemColumnList.push(
                {
                    name: 'Brand',
                    selector: row => row.punchBranchCode ? row.punchBranchCode : undefined,
                    sortable: false,
                    tech_name: 'punchBranchCode',
                    cell: (d) => [(<>
                        <Row className='fs-8 m-0 fw-bold text-gray-600'>
                            {d.groupByValues['BRANDCODE']}
                        </Row>
                    </>)]
                })

        if (groupByShop)
            systemColumnList.push(
                {
                    name: 'Organization Name',
                    selector: row => row.organizationName ? row.organizationName : undefined,
                    sortable: false,
                    tech_name: 'organizationName',
                    cell: (d) => [(<>
                        <Row className='fs-8 m-0 fw-bold text-gray-600'>
                            {d.groupByValues['ORGANIZATIONNAME']}
                        </Row>
                    </>)]
                })

        if (groupByAzadeaId)
            systemColumnList.push(
                {
                    name: 'Azadea Id',
                    selector: row => row.AZADEAID ? row.AZADEAID : undefined,
                    sortable: false,
                    tech_name: 'AZADEAID',
                    cell: (d) => [(<>
                        <Row className='fs-8 m-0 fw-bold text-gray-600'>
                            {d.groupByValues['AZADEAID']}
                        </Row>
                    </>)]
                })

        systemColumnList.push(
            {
                name: 'ATS Punch',
                selector: row => row.atsPunch ? row.atsPunch : undefined,
                sortable: false,
                tech_name: 'atsPunch',
                cell: (d) => [(<>
                    <Row className='fs-8 m-0 fw-bold text-gray-600'>
                        {d.atsPunch}
                    </Row>
                </>)]
            })

        systemColumnList.push(
            {
                name: 'Manual Punch',
                selector: row => row.manualPunch ? row.manualPunch : undefined,
                sortable: false,
                tech_name: 'manualPunch',
                cell: (d) => [(<>
                    <Row className='fs-8 m-0 fw-bold text-gray-600'>
                        {d.manualPunch}
                    </Row>
                </>)]
            })

        systemColumnList.push(
            {
                name: 'Percentage',
                // selector: row => row.manualPunch ? row.manualPunch : undefined,
                sortable: false,
                tech_name: 'Percentage',
                cell: (d) => [(<>
                    <Row className='fs-8 m-0 fw-bold text-gray-600'>
                        {((d.manualPunch / (d.manualPunch + d.atsPunch)) * 100).toFixed(2)}
                    </Row>
                </>)]
            })
        setSystemColumns(systemColumnList)
    }

    const getDataToExport = async (data: any[]) => {
        let excelDataList: any[] = []
        if (data) {
            await data.forEach(row => {
                const object = builtExportObject(row)
                excelDataList.push(object)
            })
            setSelectedExcelData(excelDataList)
        }
    }

    const hitExport = (data: any[]) => {
        document.body.classList.remove('page-loading')
        if (data && data.length > 0) {
            const parentElement = document.createElement('div');
            const element = <div><CSVLink data={data} filename={'Summary Report ' + moment().format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} id="export_data_excel" ></CSVLink></div>
            ReactDOM.render(element, parentElement);
            document.body.appendChild(parentElement)
            const inputElement = document.querySelector('#export_data_excel') as HTMLInputElement
            inputElement.click()
            document.body.removeChild(parentElement)
        }
    }

    const exportSelectedDataToExcel = async () => {
        document.body.classList.add('page-loading')
        hitExport(selectedExcelData)
    }

    const exportDataToExcel = async () => {
        let excelDataList: any[] = []
        document.body.classList.add('page-loading')
        const date_from = moment(dateFrom).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
        const date_to = moment(dateTo).utc(true)?.format(AZKHelper.FRONTEND_DATE_FORMAT)
        let groupByArray: string[] = []
        if (groupByCountry) groupByArray.push('countryName')
        if (groupByCompany) groupByArray.push('companyName')
        if (groupByBrand) groupByArray.push('brandcode')
        if (groupByShop) groupByArray.push('organizationName')
        const groupby = groupByArray.join(',')
        let entryType = null;
        if (az_adjustment_type) entryType = az_adjustment_type?.value
        const result = await axios.put(`${API_URL}/hr.employee/${currectEmployee}/search_attendence_system_records_summary_report?fromDate=${date_from}&toDate=${date_to}&azadeaId=${employeeId}&country=${az_country}&company=${az_company}&brand=${az_brand}&shop=${az_branch}&pageNumber=${1}&pageSize=${999}`, { 'fromDate': date_from, 'toDate': date_to, 'azadeaId': employeeId, 'country': az_country, 'company': az_company, 'brand': az_brand, 'shop': az_branch, 'pageNumber': 1, 'pageSize': 999, 'group_by': groupby })
        const remoteData = result.data ? result.data : []
        await remoteData.forEach(row => {
            const object = builtExportObject(row)
            excelDataList.push(object)
        })
        if (excelDataList && excelDataList.length > 0) {
            hitExport(excelDataList)
        }
    }

    const builtExportObject = (row) => {
        const object = {}
        object['Country'] = row.countryName
        if (groupByCompany) object['Company'] = row.groupByValues['COMPANYNAME']
        if (groupByBrand) object['Brand'] = row.groupByValues['BRANDCODE']
        if (groupByShop) object['Organization Name'] = row.groupByValues['BRANDCODE']
        object['ATS Punch'] = row.atsPunch
        object['Manual Punch'] = row.manualPunch
        object['Percentage'] = ((row.manualPunch / (row.manualPunch + row.atsPunch)) * 100).toFixed(2)
        return object
    }

    const onSelectedRowsChange = row => {
        getDataToExport(row.selectedRows)
    };

    const handlePageChange = page => {
        let newOffset = page;
        setOffset(newOffset);
        setSystemData([]);
        viewSystemResult(newOffset, perPage)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setSystemData([]);
        viewSystemResult(offset, newPerPage)
    };

    // #endregion Transfer List

    // #region State Dropdown

    const fetchUser = async () => {
        const from_date = moment().startOf('month').format(AZKHelper.BACKEND_DATE_FORMAT)
        const to_date = moment().startOf('month').add(1, 'month').format(AZKHelper.BACKEND_DATE_FORMAT)
        setDateFrom(from_date)
        setDateTo(to_date)
        let _auth = authHelper.getAuth()
        if (!_auth) {
            const auth = localStorage.getItem('azk-auth-hr-lite')
            if (auth) {
                _auth = JSON.parse(auth) as AuthModel
            }
        }
        GetEmployeeByUserId(_auth?.uid).then(async response => {
            let isMangerVal = false
            let isShopUserVal = true
            let isSuperManager = false
            if (response && response.is_manager_fr && response.is_manager_fr !== null) {
                setIsManger(true)
                isMangerVal = true
                setCurrectEmployee(response.id)
            } else {
                setIsManger(false)
                isMangerVal = false
                setCurrectEmployee(response.id)
            }
            if (response.is_supper_access && response.is_supper_access !== null) {
                isSuperManager = true
                setSuperManager(response?.is_supper_access);
            }
            setUserId(_auth?.uid)
            if (response.is_back_office_fr && response.is_back_office_fr !== null) {
                setShopUser(!response?.is_back_office_fr)
                isShopUserVal = !response?.is_back_office_fr
            }

            if (response.id) changeEmployeeDomain(response.id, isMangerVal, shopUser, az_country, az_company, az_branch, az_brand, isSuperManager)
        })
    }

    useEffect(() => {
        document.body.classList.remove('page-loading')
        fetchUser();
    }, []);

    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'System', label: 'system' })
        options.push({ value: 'Manual', label: 'Manual' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    const [az_adjustment_type, setAttendanceAdjustmentType] = useState<any>('');

    // #endregion Type Dropdown

    // #region Country Dropdown
    const [az_country, setCountry] = useState('');
    const [activetCountryDomain, setActivetCountryDomain] = useState(false);

    const handleCountryChange = (val) => {
        if (activetCountryDomain) {
            setCountry(val);
            setCompanyDomain(val ? `('country_id','=', ${val})` : '');
            setBranchDomain(val ? `('country_id','=', ${val})` : '');
            if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, val, az_company, az_branch, az_brand, superManager)
        }
        setActivetCountryDomain(true)
    }

    const az_country_select = azkSelect({
        compID: "az_country_select",
        modelName: "az.country",
        valueField: "id",
        titleField: "name",
        allowEmpty: true,
        isSearchable: true,
        defaultValue: az_country,
        onChange: handleCountryChange
    });
    // #endregion Country Dropdown


    // #region Company Dropdown
    const [az_company, setCompany] = useState(null);
    const [companyDomain, setCompanyDomain] = useState('');
    const [activetCompanyDomain, setActivetCompanyDomain] = useState(false);

    const handleCompanyChange = (val) => {
        if (activetCompanyDomain) {
            setCompany(val);
            setBrandefaultValue('')
            const conds: string[] = []
            if (az_country) {
                conds.push(`('country_id','=', ${az_country})`)
            }
            if (val) {
                conds.push(`('company_id','=', ${val})`)
            }
            if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, az_country, val, az_branch, az_brand, superManager)
            setBranchDomain(conds.join(','));
            if (val !== '') {
                axios.get(`${API_URL}/az.company?filters=[('id', '=', ${val ? val : '0'})]&include_fields=['brand_ids']`).then((result: any) => {
                    if (result.data && result.data.results && result.data.count > 0 && result.data.results[0].brand_ids !== null) {
                        setBrandDomain(`('id', 'in', [${result.data.results[0].brand_ids}])`);
                    } else {
                        setBrandDomain('');
                    }
                });
            } else {
                setBrandDomain('');
            }
        }
        setActivetCompanyDomain(true)
    }

    const az_company_select = azkSelect({
        compID: "az_company_select",
        modelName: "az.company",
        valueField: "id",
        titleField: "name",
        dataDomain: companyDomain,
        defaultValue: az_company,
        allowEmpty: true,
        onChange: handleCompanyChange
    });
    // #endregion Company Dropdown

    // #region Brand Dropdown
    const [az_brand, setBrand] = useState('');
    const [brandDomain, setBrandDomain] = useState('');
    const [brandefaultValue, setBrandefaultValue] = useState('');
    const [activetBrandDomain, setActivetBrandDomain] = useState(false);

    const handleBrandChange = (val) => {
        if (activetBrandDomain) {
            setBrand(val);
            setBrandefaultValue(val)
            const conds: string[] = []
            if (az_country) {
                conds.push(`('country_id','=', ${az_country})`)
            }
            if (az_company) {
                conds.push(`('az_company_id','=', ${az_company})`)
            }
            if (val) {
                conds.push(`('brand_id','=', ${val})`)
                if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, az_country, az_company, az_branch, val, superManager)
            }
            setBranchDomain(conds.join(','));
        }
        setActivetBrandDomain(true)
    }

    const az_brand_select = azkSelect({
        compID: "az_brand_select",
        modelName: "az.brand",
        valueField: "id",
        titleField: "name",
        dataDomain: brandDomain,
        allowEmpty: true,
        onChange: handleBrandChange,
        defaultValue: brandefaultValue
    });

    // #endregion Brand Dropdown


    // #region Branch Dropdown
    const [az_branch, setBranch] = useState('');
    const [branchDomain, setBranchDomain] = useState('');

    const handleBranchChange = (val) => {
        setBranch(val);
        if (currectEmployee) changeEmployeeDomain(currectEmployee, isManger, shopUser, az_country, az_company, val, az_brand, superManager)
    }

    const az_branch_select = azkSelect({
        compID: "az_branch_select",
        modelName: "az.branch",
        valueField: "id",
        titleField: "organization_name",
        allowEmpty: true,
        dataDomain: branchDomain,
        isSearchable: true,
        defaultValue: az_branch,
        onChange: handleBranchChange
    });
    // #endregion Branch Dropdown

    const changeEmployeeDomain = (employee_id, is_manager_fr, is_shop_user, country_id, company_id, branch_id, brand_id, isSuperManager) => {
        let conds: string[] = [];
        if (isSuperManager) {
            // conds.push(``)
        } else if (is_manager_fr && is_shop_user) {
            conds.push(`'|', '|', ('id', '=', ${employee_id}), ('parent_id', '=', ${employee_id}), ('super_parent_id', '=', ${employee_id})`)
        } else if (is_manager_fr && !is_shop_user) {
            conds.push(`'|', ('id', '=', ${employee_id}), ('parent_id', '=', ${employee_id})`)
        } else {
            conds.push(`('id', '=', ${employee_id})`)
        }
        if (country_id && country_id !== null) {
            conds.push(`('az_country_id', '=', ${country_id})`)
        }
        if (company_id && company_id !== null) {
            conds.push(`('az_company_id', '=', ${company_id})`)
        }
        if (branch_id && branch_id !== null) {
            conds.push(`('branch_id', '=', ${branch_id})`)
        }
        if (brand_id && brand_id !== null) {
            conds.push(`('brand_id', '=', ${brand_id})`)
        }
        setEmployeeDomain(conds.join(','))
        setEmployeeDomainReady(true)
    }
    return (
        <>
            <KTCard className='mt-3'>
                <div className='card-body'>
                    <div className='row m-0'>

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Country</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_country_select}
                            {/* end::Input */}
                            {dataReqeusted && !az_country &&
                                <div className='text-danger'>
                                    Country is required Field
                                </div>
                            }
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Company</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_company_select}
                            {/* end::Input */}
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Brand</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_brand_select}
                            {/* end::Input */}
                        </div>

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Organization Name</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {az_branch_select}
                            {/* end::Input */}
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>From</label>
                            <Form.Control
                                className='p-1'
                                type='date'
                                onChange={(event) => {
                                    setDateFrom(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                    // if (requestView) setListDataDomain(updateListDataDomain(currectEmployee, searchTerm, az_country, az_company, az_brand, az_state, myRequests, az_adjustment_reason_id, moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT), dateTo))
                                }}
                                defaultValue={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                value={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                max={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                            />
                            {dataReqeusted && !moment(dateFrom).isValid() &&
                                <div className='text-danger'>
                                    Date from is required Field
                                </div>
                            }
                        </div>

                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>To</label>
                            <Form.Control
                                className='p-1'
                                type='date'
                                onChange={(event) => {
                                    setDateTo(moment(event.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                }}
                                defaultValue={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                value={moment(dateTo).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                min={moment(dateFrom).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                            />
                            {dataReqeusted && !moment(dateTo).isValid() &&
                                <div className='text-danger'>
                                    Date to is required Field
                                </div>
                            }
                        </div>
                        <div className='fv-row mb-2 col-lg-3 col-md-6 col-12'>
                            {/* begin::Label */}
                            <label className='fw-bold fs-7 my-auto col-12 col-md-12'>Azadea ID</label>
                            {/* end::Label */}

                            {/* begin::Input */}
                            {employeeDomainReady &&
                                <AzkSelect
                                    compID="azk_temp"
                                    modelName="hr.employee"
                                    valueField="ex_az_id"
                                    titleField="azadea_employee_name"
                                    dataDomain={employeeDomain}
                                    allowEmpty={true}
                                    fieldName='employee_id'
                                    onChange={(value) => {
                                        setEmployeeId(parseInt(value))
                                    }}
                                    searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                            }
                            {/* end::Input */}
                        </div>
                        <Row className='m-0 p-0 px-6'>
                            <div className='fv-row px-0 col-lg-9 col-md-9 col-12'>
                                <div className='fv-row mb-2 px-12 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-between'>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id='country_group_by'
                                            value='country_group_by'
                                            label='Country'
                                            name='country_group_by'
                                            checked={groupByCountry}
                                            onChange={(val) => {
                                                setGroupByCountry(val.target.checked);
                                            }}
                                            className='p-1'
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id='company_group_by'
                                            value='company_group_by'
                                            label='Company'
                                            name='company_group_by'
                                            checked={groupByCompany}
                                            onChange={(val) => {
                                                setGroupByCompany(val.target.checked);
                                            }}
                                            className='p-1'
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id='brand_group_by'
                                            value='brand_group_by'
                                            label='Brand'
                                            name='brand_group_by'
                                            checked={groupByBrand}
                                            onChange={(val) => {
                                                setGroupByBrand(val.target.checked);
                                            }}
                                            className='p-1'
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id='shop_group_by'
                                            value='shop_group_by'
                                            label='Organization Name'
                                            name='shop_group_by'
                                            checked={groupByShop}
                                            onChange={(val) => {
                                                setGroupByShop(val.target.checked);
                                            }}
                                            className='p-1'
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Check
                                            type="checkbox"
                                            id='azadea_id_group_by'
                                            value='azadea_id_group_by'
                                            label='Azadea Id'
                                            name='azadea_id_group_by'
                                            checked={groupByAzadeaId}
                                            onChange={(val) => {
                                                setGroupByAzadeaId(val.target.checked);
                                            }}
                                            className='p-1'
                                        />
                                    </Col>
                                </div>

                                {dataReqeusted && !groupByCountry && !groupByBrand && !groupByCompany && !groupByShop &&
                                    <div className='text-danger'>
                                        Group by is required Field
                                    </div>
                                }
                            </div>
                            <div className='fv-row mb-2 col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-end'>
                                <button name="search-system-records" type='button' className={`btn btn-light-primary me-3 btn-sm`} onClick={() => viewSystemResult(offset, perPage)} >
                                    <KTIcon iconName='search-list' className='fs-2' />
                                    Search
                                </button>
                                <button type='button' onClick={exportSelectedDataToExcel} className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? '' : 'd-none'}`}>
                                    <KTIcon iconName='exit-up' className='fs-2' />
                                    Export Selected
                                </button>
                                <button type='button' className={`btn btn-light-primary me-3 btn-sm ${selectedExcelData && selectedExcelData.length > 0 ? 'd-none' : ''}`} onClick={exportDataToExcel} >
                                    <KTIcon iconName='exit-up' className='fs-2' />
                                    Export
                                </button>
                            </div>
                        </Row>
                    </div>
                </div>
            </KTCard >
            <KTCard className='mt-3'>
                {systemData && systemColumns && systemColumns.length > 0 && <>
                    <KTCardBody className='py-4 cutom-padding'>
                        <div className='table-responsive'>
                            <DataTable
                                columns={systemColumns}
                                data={systemData}
                                // onSort={handleSort}
                                // onRowClicked={onRowClickedFun}
                                // sortServer
                                // progressPending={pending}
                                // pagination
                                // paginationServer
                                // selectableRowDisabled={(row) => {
                                //    return diableSelectableRows && (row['state'] !== "to_approve" || row['restarted'])
                                // }}
                                // paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                selectableRows={true}
                                onSelectedRowsChange={onSelectedRowsChange}
                            />
                        </div>
                    </KTCardBody>
                </>}
            </KTCard>
        </>)
}

export default SummaryReportView;