import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios, { ResponseType } from "axios";
import * as Yup from 'yup'
import { Leave, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, FormikProps, FormikValues } from 'formik'
import { CreateLeave, UpdateLeave, GetLeaveById, MODEL_NAME } from "./_requests";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    is_hourly: Yup.boolean().notRequired(),
    is_halfDay: Yup.boolean().notRequired(),
    lookup_code: Yup.string().notRequired(),
    leave_type_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Leave type is required'),
    date_from: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Date from is required')
        .min(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'Date from must be after the first of the month'),
    date_to: Yup
        .date()
        .transform((value) => !moment(value).isValid() ? undefined : value)
        .required()
        .when('is_hourly', (is_hourly, field) =>
            (is_hourly[0] === false || !is_hourly[0]) ? field.min(Yup.ref('date_from'), 'Date to cannot be earlier than date from') : field
        ),
    half_day_from: Yup.string().transform((value) => checkTimeInput(value) ? undefined : value)
        .when('is_halfDay', (is_halfDay, field) =>
            (is_halfDay[0] === true) ? field.required('Time from is required') : field
        ),
    time_from: Yup.string().transform((value) => checkTimeInput(value) ? undefined : value)
        .when('is_hourly', (is_hourly, field) =>
            (is_hourly[0] === true) ? field.required('Time from is required') : field
        ),
    time_to: Yup.string()
        .test('required', 'Time to is required', function (value) {
            if (this.parent['is_hourly'] && (!value || value === '')) {
                return false
            } else {
                return true
            }
        })
        .test('min', 'Time to must be greater than time from', function (value) {
            const timeToDateStr = moment(this.parent['date_to']).format(AZKHelper.FRONTEND_DATE_FORMAT) + ' ' + value
            const timeFromDateStr = moment(this.parent['date_from']).format(AZKHelper.FRONTEND_DATE_FORMAT) + ' ' + this.parent['time_from']
            if (this.parent['is_hourly'] && moment(timeFromDateStr).isSameOrAfter(moment(timeToDateStr))) {
                return false
            } else {
                return true
            }
        }),
    expected_delivery_date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value)
        .when('lookup_code', (lookup_code, field) =>
            (lookup_code[0] === AZKHelper.MATERNITY_LEAVE_CODE) ? field.required('Expected delivery date required').min(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'Expected delivery date must be after the first of the month') : field
        ),
    childbirth_date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value)
        .when('lookup_code', (lookup_code, field) =>
            (lookup_code[0] === AZKHelper.PATERNITY_LEAVE_CODE) ? field.required('Child birth date required').min(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 'Child birth date must be after the first of the month') : field
        ),
    country_visited_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .when('lookup_code', (lookup_code, field) =>
            (lookup_code[0] === AZKHelper.BUSINESS_TRAVEL_LEAVE_CODE) ? field.required('Country visited required') : field
        ),
    reason_for_travel_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .when('lookup_code', (lookup_code, field) =>
            lookup_code[0] === AZKHelper.BUSINESS_TRAVEL_LEAVE_CODE
                ? field.required('Reason of travel is required')
                : field
        ),
    relationship_type_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .when('lookup_code', (lookup_code, field) =>
            (lookup_code[0] === AZKHelper.BEREAVEMENT_LEAVE_CODE) ? field.required('Relation ship required') : field
        ),
    family_member_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .when('lookup_code', (lookup_code, field) =>
            (lookup_code[0] === AZKHelper.BEREAVEMENT_LEAVE_CODE) ? field.required('Family member required') : field
        ),

})

const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<Leave>>(null);
    const [leaveTypeDomain, setLeaveTypeDomain] = useState('');
    const [familyMemberDomain, setFamilyMemberDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [hasFileAttached, setHasFileAttached] = useState<any>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();

    const [isHourlyLeave, setIsHourlyLeave] = useState<any>(false);
    const [isHalfDay, setIsHalfDay] = useState<any>(false);
    const [leaveLookUp, setLeaveLookUp] = useState<string>('1');

    const [mangerUser, setMangerUser] = useState<boolean>(false);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const checkUserType = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let domain = ``
                        if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
                        if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
                        // if (response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`'|', ('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && !response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        if (response.is_manager_fr && !response.is_back_office_fr) {
                            domain = domain.concat(`'|', '|', ('id', '=', ${response.id}), ('parent_id', '=', ${response.id}), ('super_parent_id', '=', ${response.id})`)
                        } else {
                            domain = domain.concat(`('id', '=', ${response.id})`)
                        }
                        setEmployeeDomain(domain)
                    }

                })
            }
        }
    }

    const handleLeaveTypeChange = (leaveId) => {
        formRef.current?.setFieldValue('half_day_from', undefined);
        formRef.current?.setFieldValue('time_from', undefined);
        formRef.current?.setFieldValue('time_to', undefined);
        formRef.current?.setFieldValue('relationship_type_id', undefined);
        formRef.current?.setFieldValue('family_member_id', undefined);
        formRef.current?.setFieldValue('childbirth_date', undefined);
        formRef.current?.setFieldValue('expected_delivery_date', undefined);
        formRef.current?.setFieldValue('country_visited_id', undefined);
        formRef.current?.setFieldValue('reason_for_travel_id', '');
        if (leaveId !== '' && leaveId) {
            axios.get(`${API_URL}/az.leave.type?filters=[('id', '=', ${leaveId ? leaveId : '0'})]&include_fields=['is_hourly', 'lookup_code']`).then((result: any) => {
                if (result.data && result.data.results && result.data.count > 0 && result.data.results[0] !== null) {
                    setIsHourlyLeave(result.data.results[0].is_hourly)
                    setLeaveLookUp(result.data.results[0].lookup_code)
                    formRef.current?.setFieldValue('date_from', moment().format(AZKHelper.FRONTEND_DATE_FORMAT));
                    formRef.current?.setFieldValue('date_to', moment().format(AZKHelper.FRONTEND_DATE_FORMAT));
                    setIsHalfDay(result.data.results[0].lookup_code === AZKHelper.HALF_DAY)
                    formRef.current?.setFieldValue('is_hourly', result.data.results[0].is_hourly)
                    formRef.current?.setFieldValue('lookup_code', result.data.results[0].lookup_code)
                    if (result.data.results[0].is_hourly) {
                        formRef.current?.setFieldValue('time_from', moment().format(AZKHelper.FRONTEND_TIME_FORMAT));
                        formRef.current?.setFieldValue('time_to', moment().add(1, 'hours').format(AZKHelper.FRONTEND_TIME_FORMAT));
                    }
                    if (result.data.results[0].lookup_code === AZKHelper.HALF_DAY) {
                        formRef.current?.setFieldValue('half_day_from', moment().format(AZKHelper.FRONTEND_TIME_FORMAT));
                    }
                    if (result.data.results[0].lookup_code === AZKHelper.MATERNITY_LEAVE_CODE) {
                        formRef.current?.setFieldValue('expected_delivery_date', moment().format(AZKHelper.FRONTEND_DATE_FORMAT));
                    }
                    if (result.data.results[0].lookup_code === AZKHelper.PATERNITY_LEAVE_CODE) {
                        formRef.current?.setFieldValue('childbirth_date', moment().format(AZKHelper.FRONTEND_DATE_FORMAT));
                    }
                }
            });
        }
    }

    const handleEmployeeChange = async function (employeeId, LeaveData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    const andCondition = encodeURIComponent('&')
                    setLeaveTypeDomain(tempEmployee.az_country_id ? `'${andCondition}', ('country_id','=', ${tempEmployee.az_country_id?.id}), '|', ('payroll_id', '=', ${tempEmployee.payroll_id?.id}), ('payroll_id', '=', False)` : '');
                    // handleJobChange(false)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                    })
                    if (LeaveData) {
                        pobulateFormData(LeaveData)
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
            })
        }
        return true
    }

    const pobulateFormData = async (data?: Leave) => {
        if (data) {
            if (data.document) setHasFileAttached(true)
            else setHasFileAttached(false)
            setIsHourlyLeave(data.leave_type_id.is_hourly)
            setLeaveLookUp(data.leave_type_id.lookup_code)
            setIsHalfDay(data.leave_type_id.lookup_code === AZKHelper.HALF_DAY)
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                time_set: data.time_set,
                azadea_employee_name: data.azadea_employee_name,
                leave_type_id: data?.leave_type_id?.id,
                date_from: moment(data.date_from).format(AZKHelper.FRONTEND_DATE_FORMAT),
                date_to: moment(data.date_to).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_from: data.time_from,
                time_to: data.time_to,
                relationship_type_id: data?.relationship_type_id?.id,
                family_member_id: data?.family_member_id?.id,
                childbirth_date: moment(data.childbirth_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                expected_delivery_date: moment(data.expected_delivery_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                country_visited_id: data?.country_visited_id?.id,
                reason_for_travel_id: data?.reason_for_travel_id?.id,
                is_hourly: data?.leave_type_id?.is_hourly,
                lookup_code: data?.leave_type_id?.lookup_code,
                document: data.document,
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/self-service/leave-submission/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/self-service/leave-submission/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }


    useEffect(() => {
        checkUserType()
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetLeaveById(parseInt(id)).then(((data: Leave) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_write)
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/leave-submission/`, { state: {}, replace: true });
    }

    const downloadFilePDF = (responseType: ResponseType = 'blob') => {
        const file_id = formRef?.current?.values.document
        if (file_id) {
            axios
                .get(`${API_URL}/attachments/${file_id.id}`, {
                    responseType,
                })
                .then((response) => {
                    let file_name = response.headers['content-disposition']
                    file_name = file_name.replace('attachment; filename=', '')
                    file_name = file_name.replaceAll('\"', '')
                    const blob = new Blob([response.data], { type: response.headers['content-type'] })
                    const url = window.URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', file_name)
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(url)
                })
                .catch((error) => {
                    console.error('Download error:', error)
                })
        }
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/self-service/leave-submission/`, { state: {}, replace: true })
        Swal.fire({
            position: "top-right",
            title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const onSubmitHandler = async (values: Leave, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id)) {
                await UpdateLeave(values).then(data => {
                    if (id) handleResultOfsubmit(parseInt(id), true)
                })
            } else {
                await CreateLeave(values).then(data => {
                    setObjectId(data?.id)
                    setHitSend(true)
                })
            }
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        document.body.classList.add('page-loading')
        if (files) {
            await Array.from(files).forEach((file, index) => {
                const fileType = file.type;
                if (AZKHelper.ALLOWED_DOC_TYPES.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const arrayBuffer = reader.result;
                        let datas = ''
                        if (arrayBuffer instanceof ArrayBuffer) {
                            let binary = '';
                            const uint8Array = new Uint8Array(arrayBuffer);
                            const len = uint8Array.byteLength;
                            for (let i = 0; i < len; i++) {
                                binary += String.fromCharCode(uint8Array[i]);
                            }
                            datas = window.btoa(binary)
                        } else if (arrayBuffer) {
                            datas = arrayBuffer.split(',')[1]
                        }
                        const formData = new FormData();
                        formData.append('res_model', MODEL_NAME);
                        if (id) formData.append('res_id', '' + parseInt(id));
                        formData.append('name', 'FS' + file.name);
                        formData.append('type', "binary");
                        formData.append('datas', datas);
                        axios.post(`${API_URL}/attachments/upload`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            if (id && response && response.data) {
                                axios.put(`${API_URL}/${MODEL_NAME}/${parseInt(id)}/upload_pdf`, { 'attachment_id': response.data['id'] }).then(response => {
                                    // updateForm(parseInt(id))
                                    document.body.classList.remove('page-loading')
                                    Swal.fire({
                                        position: "top-right",
                                        title: 'Success!',
                                        text: 'The FS had been uploaded!',
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                                        toast: true,
                                        iconColor: 'white',
                                        customClass: {
                                            popup: 'colored-toast'
                                        },
                                        timerProgressBar: true
                                    })
                                }, err => {
                                    document.body.classList.remove('page-loading')
                                })
                            } else {
                                formRef?.current?.setFieldValue('document', response.data.id)
                                document.body.classList.remove('page-loading')
                            }
                            return response.data.id
                        }).catch(function (error) {
                            console.log(error)
                            document.body.classList.remove('page-loading')
                            return error;
                        })
                    }
                    reader.readAsArrayBuffer(file);
                } else {
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Sorry!',
                        text: 'Please check you attachments, allowed file types (' + AZKHelper.ALLOWED_DOC_TYPE + ')!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                };
            })
        }
    }


    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            backLink={'/self-service/leave-submission/'}
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/self-service/leave-submission/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" },
                                                // { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                                hasFileAttached && { title: "Download PDF", cssClass: "btn btn-light btn-light-warning", icon: "fa-download", callback: downloadFilePDF },
                                            ]} />}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit || readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            {employeeDomain && employeeDomain !== '' &&
                                                                <AzkSelect
                                                                    compID="azk_temp"
                                                                    modelName="hr.employee"
                                                                    valueField="id"
                                                                    titleField="display_name_fr"
                                                                    dataDomain={employeeDomain}
                                                                    allowEmpty={true}
                                                                    fieldName='employee_id'
                                                                    onChange={(value) => {
                                                                        setFieldValue('employee_id', parseInt(value))
                                                                        handleEmployeeChange(value, undefined)
                                                                    }}
                                                                    defaultValue={values.employee_id}
                                                                    disabled={!canEdit}
                                                                    searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            }
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Leave Detail</h4>
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Leave Type
                                                                    </Form.Label>
                                                                    <AzkSelect
                                                                        compID="az_leave_type_id_select"
                                                                        modelName="az.leave.type"
                                                                        valueField="id"
                                                                        titleField="name"
                                                                        dataDomain={leaveTypeDomain}
                                                                        isMultiple={false}
                                                                        allowEmpty={true}
                                                                        fieldName='leave_type_id'
                                                                        disabled={!canEdit}
                                                                        onChange={(value) => {
                                                                            if (value !== null) {
                                                                                setFieldValue('leave_type_id', parseInt(value))
                                                                                handleLeaveTypeChange(parseInt(value))
                                                                            } else {
                                                                                setFieldValue('leave_type_id', undefined)
                                                                                setLeaveLookUp('')
                                                                            }
                                                                        }}
                                                                        defaultValue={values.leave_type_id} />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='leave_type_id' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Date from
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        required
                                                                        className='p-1'
                                                                        type='date'
                                                                        onChange={(value) => {
                                                                            const date = moment(value.target.value).startOf("day")
                                                                            formRef.current?.setFieldValue('date_from', date.format(AZKHelper.FRONTEND_DATE_FORMAT));
                                                                        }}
                                                                        disabled={!canEdit}
                                                                        defaultValue={moment(values.date_from).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                    />

                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='date_from' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Date To
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type='date'
                                                                        onChange={(value) => {
                                                                            const date = moment(value.target.value).startOf("day")
                                                                            formRef.current?.setFieldValue('date_to', date.format(AZKHelper.FRONTEND_DATE_FORMAT));
                                                                        }}
                                                                        disabled={!canEdit}
                                                                        defaultValue={moment(values.date_to).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                    />

                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='date_to' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            {isHalfDay && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Time From
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            className='p-1'
                                                                            type='time'
                                                                            onChange={(value) => {
                                                                                formRef.current?.setFieldValue('half_day_from', value.target.value);
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={values.half_day_from}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='half_day_from' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                            {isHourlyLeave && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Time From
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            required
                                                                            className='p-1'
                                                                            type='time'
                                                                            onChange={(value) => {
                                                                                formRef.current?.setFieldValue('time_from', value.target.value);
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={values.time_from}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='time_from' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>

                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Time To
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='time'
                                                                            onChange={(value) => {
                                                                                formRef.current?.setFieldValue('time_to', value.target.value);
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={values.time_to}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='time_to' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                            {AZKHelper.MATERNITY_LEAVE_CODE === leaveLookUp && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Expected delivery date
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            onChange={(value) => {
                                                                                const date = moment(value.target.value).startOf("day")
                                                                                formRef.current?.setFieldValue('expected_delivery_date', date.format(AZKHelper.FRONTEND_DATE_FORMAT));
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.expected_delivery_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='expected_delivery_date' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                            {AZKHelper.PATERNITY_LEAVE_CODE === leaveLookUp && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Childbirth date
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            className='p-1'
                                                                            type='date'
                                                                            onChange={(value) => {
                                                                                const date = moment(value.target.value).startOf("day")
                                                                                formRef.current?.setFieldValue('childbirth_date', date.format(AZKHelper.FRONTEND_DATE_FORMAT));
                                                                            }}
                                                                            disabled={!canEdit}
                                                                            value={moment(values.childbirth_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        // defaultValue={moment(values.childbirth_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                        />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='childbirth_date' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                            {AZKHelper.MEDICAL_LEAVE_CODE === leaveLookUp && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label>
                                                                            Upload Report
                                                                        </Form.Label>
                                                                        <input
                                                                            accept={AZKHelper.ALLOWED_DOC_TYPE}
                                                                            disabled={transactionClosed}
                                                                            type='file'
                                                                            id='file'
                                                                            className="p-1"
                                                                            onChange={handleFileChange} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                            {AZKHelper.BUSINESS_TRAVEL_LEAVE_CODE === leaveLookUp && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Country Visited
                                                                        </Form.Label>
                                                                        <AzkSelect
                                                                            compID="az_country_visited_id"
                                                                            modelName="res.country"
                                                                            valueField="id"
                                                                            titleField="name"
                                                                            dataDomain=''
                                                                            isMultiple={false}
                                                                            allowEmpty={true}
                                                                            fieldName='country_visited_id'
                                                                            disabled={!canEdit}
                                                                            onChange={(value) => {
                                                                                setFieldValue('country_visited_id', parseInt(value))
                                                                            }}
                                                                            defaultValue={values.country_visited_id} />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='country_visited_id' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Reason for travel
                                                                        </Form.Label>
                                                                        <AzkSelect
                                                                            compID="reason_for_travel_id"
                                                                            modelName="az.travel.reason"
                                                                            valueField="id"
                                                                            titleField="name"
                                                                            dataDomain=''
                                                                            isMultiple={false}
                                                                            allowEmpty={true}
                                                                            fieldName='reason_for_travel_id'
                                                                            disabled={!canEdit}
                                                                            onChange={(value) => {
                                                                                setFieldValue('reason_for_travel_id', parseInt(value))
                                                                            }}
                                                                            defaultValue={values.reason_for_travel_id} />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='reason_for_travel_id' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                            {AZKHelper.BEREAVEMENT_LEAVE_CODE === leaveLookUp && <>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Relation Ship
                                                                        </Form.Label>
                                                                        <AzkSelect
                                                                            compID="az_relationship_type_id"
                                                                            modelName="az.relation.ship.type"
                                                                            valueField="id"
                                                                            titleField="name"
                                                                            dataDomain=''
                                                                            isMultiple={false}
                                                                            allowEmpty={true}
                                                                            fieldName='relationship_type_id'
                                                                            disabled={!canEdit}
                                                                            onChange={(value) => {
                                                                                setFieldValue('relationship_type_id', parseInt(value))
                                                                                setFieldValue('family_member_id', undefined)
                                                                                setFamilyMemberDomain(value ? `('relationship_id','=', ${parseInt(value)})` : '');
                                                                            }}
                                                                            defaultValue={values.relationship_type_id} />
                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='relationship_type_id' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg="4" md="4" sm="6" xs="12">
                                                                    <Form.Group className='mb-2'>
                                                                        <Form.Label className='required'>
                                                                            Family Member
                                                                        </Form.Label><AzkSelect
                                                                            compID="az_family_member_type_id"
                                                                            modelName="az.family.member"
                                                                            valueField="id"
                                                                            titleField="name"
                                                                            dataDomain={familyMemberDomain}
                                                                            isMultiple={false}
                                                                            allowEmpty={true}
                                                                            fieldName='family_member_id'
                                                                            disabled={!canEdit}
                                                                            onChange={(value) => {
                                                                                setFieldValue('family_member_id', parseInt(value))
                                                                            }}
                                                                            defaultValue={values.family_member_id} />

                                                                        <div className='text-danger'>
                                                                            <ErrorMessage name='family_member_id' />
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>}
                                                        </Row>
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        {!canValidate && hasFileAttached &&
                                                            <button
                                                                title="Download PDF"
                                                                type='reset'
                                                                onClick={() => downloadFilePDF()}
                                                                className='btn btn-light btn-light-warning btn-sm'
                                                                data-kt-users-modal-action='Download PDF'
                                                            >
                                                                <i className={`fa fa-fw o_button_icon fa-download`}></i>
                                                                <span className='indicator-label'>Download PDF</span>

                                                            </button>
                                                        }
                                                        {!canValidate &&
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default Transactionform;

function checkTimeInput(value) {
    const timeDateStr = '1990/1/1 ' + value
    const timeDate = moment(timeDateStr)
    return !timeDate.isValid
}